import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { LoaderState } from './interfaces/loader-state.interface';
import { LoaderService } from './services/loader.service';

@UntilDestroy()
@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
    @Input() small = false;
    @Input() set enabled(enabled: boolean) {
        this._state$.next({
            ...this._state$.value,
            enabled,
            top: this.loaderService.top,
        });
    }

    private _state$ = new BehaviorSubject<LoaderState>(null);

    public get state$(): Observable<LoaderState> {
        return this._state$.asObservable();
    }

    constructor(private readonly loaderService: LoaderService) {}

    public ngOnInit(): void {
        this.onShowHeaderChanged();
    }

    private onShowHeaderChanged(): void {
        this.loaderService.showHeader$
            .pipe(
                tap((header) => {
                    this._state$.next({
                        ...this._state$.value,
                        header,
                    });
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
