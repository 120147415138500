import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { catchError, take, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { FormPatientCardioService } from '@services/form/form-patient-cardio.service';
import { SidebarService } from '@ui/sidebar';
import { PatientService } from '../patient';
import { FormatFormService } from './format-form.service';
import { ErrorFormService } from './error-form.service';

@Injectable({ providedIn: 'root' })
export class EditionCardioService {
    patient;
    cancelForm;

    constructor(
        private http: HttpClient,
        private patientService: PatientService,
        private formatService: FormatFormService,
        private formPatientCardioService: FormPatientCardioService,
        private errorService: ErrorFormService,
        private router: Router,
        private sidebarService: SidebarService,
    ) {}

    initEdition(id) {
        this.errorService.resetErrors();

        const promises = [
            this.initPatient(id),
            this.formPatientCardioService.loadCarestaff(),
        ];
        return forkJoin(promises);
    }

    initPatient(id) {
        return this.patientService
            .getPatient(id, ['billingPackage'], 'cardio')
            .pipe(
                tap((patient) => {
                    this.patient = this.formatService.initPatient(
                        patient,
                        'cardio',
                    );
                }),
            );
    }

    editPatient() {
        if (this.patient.demo) {
            this.sidebarService.show();
            this.router.navigate(['patient', this.patient._id]);
            return;
        }

        const formData = this.formatService.formatPatientCardio(this.patient);

        return this.http
            .put<any>(
                `${environment.apiUrl}/patient/${this.patient._id}`,
                formData,
                {
                    params: { disease: 'cardio' },
                },
            )
            .pipe(
                take(1),
                tap(() => {
                    this.sidebarService.show();
                    this.router.navigate(['patient', this.patient._id]);
                }),
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return of(err);
                }),
            );
    }
}
