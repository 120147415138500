import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { environment } from '@environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '@ui/sidebar';

@Injectable({ providedIn: 'root' })
export class RedirectGuard implements CanActivate {
    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
        private sidebarService: SidebarService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const regex = new RegExp(environment.newcardUrl);
        let queryParamsString = '';
        const { type } = route.params;
        const { externalUrl } = route.data;
        const queryParams = route.queryParams;

        if (regex.test(externalUrl)) {
            this.sidebarService.changePSA = true;
            queryParamsString = new URLSearchParams(queryParams).toString();
            if (queryParamsString) {
                queryParamsString = `?${queryParamsString}`;
            }
            if (!type) {
                window.location.href = `${externalUrl}${queryParamsString}`;
            } else {
                window.location.href = `${externalUrl}/${type}${queryParamsString}`;
            }

            return false;
        }

        this.toastr.error(this.translate.instant('error'));

        return false;
    }
}
