import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavigatorService {
    constructor(private http: HttpClient) {}

    getRoutes() {
        return this.http
            .get<any>(`${environment.apiUrl}/navigation`)
            .pipe(map((result) => result.routes));
    }
}
