import { Component, EventEmitter } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';

@UntilDestroy()
@Component({
    templateUrl: './inclusion-fast-confirmation.component.html',
    styleUrls: ['./inclusion-fast-confirmation.component.scss'],
})
export class InclusionFastConfirmationComponent {
    public onInclude = new EventEmitter();
    public onIncludeAndComplete = new EventEmitter();

    constructor(private bsModalRef: BsModalRef) {}

    include() {
        this.onInclude.emit();
        this.bsModalRef.hide();
    }

    includeAndComplete() {
        this.onIncludeAndComplete.emit();
        this.bsModalRef.hide();
    }

    close() {
        this.bsModalRef.hide();
    }
}
