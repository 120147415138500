import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { LoaderState } from '../interfaces/loader-state.interface';

@Directive({
    selector: '[appLoaderState]',
})
export class LoaderStateDirective {
    @Input()
    public set appLoaderState(state: LoaderState) {
        this._state = state;
        this._element.nativeElement.style.top = `${String(state.top ?? 0)}px`;
    }

    public get appLoaderState(): LoaderState {
        return this._state;
    }

    private _state!: LoaderState;

    @HostBinding('class.loader_enabled')
    public get enabled(): boolean {
        return this.appLoaderState?.enabled;
    }

    @HostBinding('class.loader_disabled')
    public get disabled(): boolean {
        return !this.appLoaderState?.enabled;
    }

    @HostBinding('class.loader_show-header')
    public get header(): boolean {
        return this.appLoaderState?.header;
    }

    constructor(private readonly _element: ElementRef<HTMLDivElement>) {}
}
