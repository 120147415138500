import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader.component';
import { LoaderStateDirective } from './directives/loader-state.directive';

@NgModule({
    declarations: [LoaderComponent, LoaderStateDirective],
    imports: [CommonModule],
    exports: [LoaderComponent],
})
export class LoaderModule {}
