import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appIconHost]',
})
export class IconDirective {
    constructor(
        private readonly _viewContainer: ViewContainerRef,
        private readonly _templateRef: TemplateRef<any>,
    ) {}
}
