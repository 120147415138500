import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { FormPatientCardioService } from '@services/form/form-patient-cardio.service';
import { AuthService } from '../security';
import { ErrorFormService } from './error-form.service';
import { FormatFormService } from './format-form.service';

@Injectable({ providedIn: 'root' })
export class InclusionCardioService {
    currentUser;
    patient;
    comment = '';

    constructor(
        private http: HttpClient,
        private formatService: FormatFormService,
        private formPatientCardioService: FormPatientCardioService,
        private errorService: ErrorFormService,
        private authService: AuthService,
    ) {
        this.initUser();
        this.initInclusionFast();
    }

    initInclusionFast(): Observable<any> {
        this.errorService.resetErrors();
        this.patient = {
            identityDoc: undefined,
            absenceContact: {},
            phones: {},
            signedConsent: true,
            medicalData: {},
            inclusion: {
                type: 'commonLaw',
                enableScale: true,
                enableTensio:
                    this.authService.isAdmin || this.currentUser.enableTensio,
                billingPackage: 'tvb',
            },
            address: {},
            prescriber: {},
            toComplete: true,
            specialistId: '',
        };
        if (!this.authService.isAdmin) {
            this.patient.specialistId = this.currentUser?._id;
            return of(true);
        }
        return this.formPatientCardioService.loadCarestaff();
    }

    canSavePatientFast() {
        return (
            this.patient.specialistId &&
            this.patient.medicalData.currentRate &&
            this.patient.medicalData.systolicFunction &&
            this.patient.lastName &&
            this.patient.firstName &&
            this.patient.phones.home &&
            this.formPatientCardioService.checkCarestaffPatient(this.patient)
        );
    }

    includedPatient() {
        const formData = this.formatService.formatPatientFast(this.patient);
        formData.append('comment', this.comment);

        return this.http
            .post<any>(`${environment.apiUrl}/patient`, formData, {
                params: { disease: 'cardio' },
            })
            .pipe(
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(err);
                }),
            );
    }

    private initUser(): void {
        this.authService.currentUser
            .pipe(tap((user) => (this.currentUser = user)))
            .subscribe();
    }

    checkExistance(patient) {
        const httpParams = new HttpParams()
            .append('firstName', patient.firstName)
            .append('lastName', patient.lastName)
            .append('nir', patient.socialSecurityNumber);

        return this.http
            .get(`${environment.apiUrl}/patient`, { params: httpParams })
            .pipe(
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(err);
                }),
            );
    }
}
