import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class FormPatientCardioService {
    careStaffs;

    constructor(private http: HttpClient, private authService: AuthService) {}

    loadCarestaff() {
        if (!this.authService.isAdmin) {
            return of(true);
        }
        return forkJoin([
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/cardiologist`,
            ),
            this.http.get<any>(`${environment.apiUrl}/users/simple/doctor`),
            this.http.get<any>(`${environment.apiUrl}/users/simple/ide`),
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/therapeutic_coach`,
            ),
            this.http.get<any>(`${environment.apiUrl}/users/simple/installer`),
        ]).pipe(
            map(
                ([cardio, doctor, ide, therapeuticCoach, installer]) =>
                    (this.careStaffs = {
                        cardio: cardio.users,
                        doctor: doctor.users,
                        ide: ide.users,
                        therapeuticCoach: therapeuticCoach.users,
                        installer: installer.users,
                    }),
            ),
        );
    }

    loadCarestaffFast() {
        if (!this.authService.isAdmin) {
            return of(true);
        }
        this.http
            .get<any>(`${environment.apiUrl}/users/simple/cardiologist`)
            .subscribe((cardio) => (this.careStaffs.cardio = cardio.users));
    }

    checkInclusionModePatient(patient) {
        return patient.specialistId;
    }

    checkIdentityPatient(patient): boolean {
        return (
            patient.sex &&
            patient.lastName &&
            patient.firstName &&
            patient.birthDate &&
            patient.socialSecurityNumber
        );
    }

    checkContactPatient(patient): boolean {
        return (
            (patient.phones.first || patient.phones.second) &&
            patient.address?.completeAddress
        );
    }

    checkMedicalDataPatient(patient): boolean {
        return (
            patient.medicalData.size &&
            patient.medicalData.currentRate &&
            patient.medicalData.systolicFunction &&
            patient.medicalData.renalFailure &&
            patient.medicalData.initWeight &&
            patient.medicalData.stimulator
        );
    }

    checkCarestaffPatient(patient): boolean {
        return (
            !patient.specialistId?.sharedProfile ||
            (patient.prescriber?.lastname && patient.prescriber?.firstname)
        );
    }

    checkSettingsPatient(patient) {
        return patient.alertInterval;
    }
}
