import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-cardio',
    templateUrl: './cardio.component.svg',
    styleUrls: ['./cardio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardioComponent {
    @Input() disabled = true;
}
