// tslint:disable: member-ordering
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    startWith,
} from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthService } from '@services/security';
import { FormControl } from '@angular/forms';
import { of, tap } from 'rxjs';

import { LoaderService } from '@ui/loader';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@services/user';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '@ui/sidebar';

@UntilDestroy()
@Component({
    selector: 'new-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    @Input() h1;
    @Input() back;
    @Input() show = false;
    @Input() diseaseShow = false;
    @Input() actions = false;
    @Input() debounce = 300;
    @Output() searchChanged = new EventEmitter<string>();
    @Input() profile = false;

    languages = [
        {
            value: 'fr',
            viewValue: 'Français',
            img: 'assets/image/flag-france.svg',
        },
        {
            value: 'en',
            viewValue: 'English',
            img: 'assets/image/flag-UK.svg',
        },
    ];
    selected;
    lang;
    currentUser;

    @HostListener('window:resize', ['$event.target'])
    private windowResize(wnd: Window): void {
        this.resize(wnd.innerWidth);
    }

    private type;
    private width;

    public searchControl: FormControl;

    color;
    searchText;
    showSearchbar = false;

    constructor(
        private readonly router: Router,
        public authService: AuthService,
        private readonly translate: TranslateService,
        private loaderService: LoaderService,
        private toastr: ToastrService,
        private userService: UserService,
        private sidebarService: SidebarService,
    ) {}

    ngOnInit(): void {
        this.initUser();
        this.initSearchControl();
    }

    public search(): void {
        this.searchControl.setValue(this.searchControl.value);
    }

    public redirect(): void {
        this.router.navigate([this.back]);
    }

    public cleanSearch(): void {
        this.searchText = '';
        this.searchControl.setValue('');
        this.searchChanged.emit('');
    }

    private initSearchControl(): void {
        this.searchControl = new FormControl('');
        this.searchControl.valueChanges
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                map((search: string) => {
                    return search.trim();
                }),
                filter((search: string) => {
                    return search.length > 0;
                }),
                debounceTime(this.debounce),
                tap((search: string) => {
                    this.searchChanged.emit(search);
                    this.searchText = true;
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private initHeader(user): void {
        this.type = user.type;
        this.color = this.initColor();
    }

    private initColor(): string {
        if (this.width >= 768) {
            return '';
        }

        return this.sidebarService.getUserTheme(this.type, 'header');
    }

    private resize(width): void {
        this.width = width;
        this.color = this.initColor();
    }

    private initUser(): void {
        this.authService.currentUser
            .pipe(
                tap((user) => {
                    this.initHeader(user);
                    this.currentUser = user;
                    this.lang = user.language;
                    if (this.lang === 'fr') {
                        this.selected = this.languages[0];
                    } else {
                        this.selected = this.languages[1];
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    changeLanguage(event): void {
        this.loaderService.enable();
        this.lang = event.value.value;
        const params = { language: this.lang };

        this.userService
            .updateCurrentUser(this.currentUser._id, params, 'language')
            .pipe(
                tap(() => {
                    this.translate.use(this.lang);
                    this.loaderService.disable();
                }),
                catchError((err) => {
                    this.toastr.error(this.translate.instant('error'));
                    this.loaderService.disable();
                    return of(err);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    toggleSidebar(): void {
        this.sidebarService.toggle();
    }
}
