import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// TODO: Need to improve service via LoaderState interface
@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private _enabled$ = new BehaviorSubject<boolean>(false);
    private _top$ = new BehaviorSubject<number>(63);
    private _showHeader$ = new BehaviorSubject<boolean>(false);

    public get enabled$(): Observable<boolean> {
        return this._enabled$.asObservable();
    }

    public get showHeader$(): Observable<boolean> {
        return this._showHeader$.asObservable();
    }

    public get top$(): Observable<number> {
        return this._top$.asObservable();
    }

    public get enabled(): boolean {
        return this._enabled$.value;
    }

    public get top(): number {
        return this._top$.value;
    }

    public enable(): void {
        this._enabled$.next(true);
    }

    public disable(): void {
        this._enabled$.next(false);
    }

    public showHeader(top?: number): void {
        this._showHeader$.next(true);

        if (top) {
            this._top$.next(top);
        }
    }

    public hideHeader(): void {
        this._showHeader$.next(false);
    }
}
