import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';

import { environment } from '@environments/environment';
import { SidebarService } from '@ui/sidebar';

@Injectable({ providedIn: 'root' })
export class RedirectAlertGuard implements CanActivate {
    constructor(private sidebarService: SidebarService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.sidebarService.changePSA = true;
        const regex = new RegExp(environment.newcardUrl);
        const { id } = route.params;
        const { externalUrl } = route.data;

        if (regex.test(externalUrl)) {
            window.location.href = `${externalUrl}/${id}`;
        }

        return false;
    }
}
