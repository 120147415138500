<div
    [class]="color"
    class="header d-none d-md-flex flex-row align-items-center"
>
    <ng-container *ngIf="back">
        <app-arrow-left (click)="redirect()"></app-arrow-left>
    </ng-container>

    <span [translate]="h1"></span>
    <ng-container *ngIf="show">
        <div class="search-container">
            <input
                [formControl]="searchControl"
                [placeholder]="'search' | translate"
                class="search"
                type="text"
            />
            <ng-container *ngIf="!searchText">
                <app-search
                    (click)="search()"
                    class="search-container__icon"
                ></app-search>
            </ng-container>
            <ng-container *ngIf="searchText">
                <app-close
                    (click)="cleanSearch()"
                    [active]="false"
                    class="search-container__icon"
                ></app-close>
            </ng-container>
        </div>
    </ng-container>

    <div class="d-flex content">
        <ng-content></ng-content>
    </div>
</div>

<ng-container *ngIf="show">
    <div
        [class]="color"
        class="mobile-search d-flex d-md-none align-items-center"
    >
        <ng-container *ngIf="!showSearchbar; else searchBar">
            <div>
                <app-mobile-kebab-menu
                    class="menu"
                    (click)="toggleSidebar()"
                ></app-mobile-kebab-menu>
                <span [translate]="h1"></span>
                <app-search
                    (click)="showSearchbar = !showSearchbar"
                ></app-search>
                <ng-container *ngIf="searchText">
                    <app-close [active]="true"></app-close>
                </ng-container>
            </div>
        </ng-container>

        <ng-template #searchBar>
            <app-arrow-left
                (click)="showSearchbar = !showSearchbar; cleanSearch()"
                [disabled]="true"
            ></app-arrow-left>
            <input
                class="search"
                type="text"
                [formControl]="searchControl"
                [placeholder]="'search' | translate"
            />
            <div class="clean">
                <ng-container *ngIf="searchText">
                    <app-close
                        [active]="true"
                        (click)="cleanSearch()"
                    ></app-close>
                </ng-container>
            </div>
        </ng-template>
    </div>
</ng-container>

<ng-container *ngIf="!show">
    <div
        [class]="color"
        class="header-mobile d-flex d-md-none flex-row align-items-center"
    >
        <app-mobile-kebab-menu
            class="menu"
            (click)="toggleSidebar()"
        ></app-mobile-kebab-menu>
        <ng-container *ngIf="back">
            <app-arrow-left (click)="redirect()" class="ms-2"></app-arrow-left>
        </ng-container>
        <span [translate]="h1" class="ms-3"></span>
        <ng-container *ngIf="profile">
            <div class="lng">
                <mat-form-field appearance="none">
                    <mat-select
                        [(ngModel)]="selected"
                        (selectionChange)="changeLanguage($event)"
                    >
                        <mat-select-trigger>
                            <img width="22" [src]="selected.img" />
                        </mat-select-trigger>
                        <mat-option
                            *ngFor="let lang of languages"
                            [value]="lang"
                        >
                            <img alt="flag" width="24" [src]="lang.img" />
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
</ng-container>
