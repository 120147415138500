import { Injectable } from '@angular/core';
import { cloneDeep, isNumber } from 'lodash';

import { PatientService } from '@services/patient';
import { tap } from 'rxjs';
import { AuthService } from '../security';
import { ConversionService } from '../biologic-data';

@Injectable({ providedIn: 'root' })
export class FormatFormService {
    currentUser;
    constructor(
        private authService: AuthService,
        private conversionService: ConversionService,
        private patientService: PatientService,
    ) {
        this.initUser();
    }

    formatPatientCardio(p) {
        const patient = cloneDeep(p);
        patient.toComplete = false;

        if (patient.medicalData.nyha === '-') {
            patient.medicalData.nyha = undefined;
        }

        if (!patient.specialistId.sharedProfile) {
            patient.prescriber = {};
        }

        if (patient.inclusion.type !== 'commonLaw') {
            patient.inclusion.modalTemplate = false;
        }

        if (this.authService.isAdmin) {
            this.formatPS(patient);
        }
        return this.createFormData(patient);
    }

    formatPatientFast(p) {
        const patient = cloneDeep(p);
        patient.toComplete = true;
        if (this.authService.isAdmin) {
            this.formatPS(patient);
        }
        return this.createFormData(patient);
    }

    formatPatientNephro(p) {
        p.treatmentImmunosuppressant = p.treatmentImmunosuppressant?.filter(
            ({ name, value, frequency, borne }) =>
                name ||
                isNumber(value) ||
                isNumber(frequency) ||
                isNumber(borne.min) ||
                isNumber(borne.max),
        );

        const patient = cloneDeep(p);
        delete patient.logs;

        if (this.authService.isAdmin) {
            this.formatPS(patient);
        }

        return this.createFormData(patient);
    }

    formatPS(patient) {
        patient.specialistId = patient.specialistId?._id;
        patient.doctorId = patient.doctorId?._id;
        patient.ideId = patient.ideId?._id;
        patient.therapeutic_coachId = patient.therapeutic_coachId?._id;
        patient.installerId = patient.installerId?._id;
        patient.transplant_centerId = patient.transplant_centerId?._id;
    }

    createFormData(patient) {
        const formData: FormData = new FormData();

        if (patient.prescription) {
            formData.append(
                'prescription',
                patient.prescription,
                patient.prescription.name,
            );
        }

        formData.append('patient', JSON.stringify(patient));

        return formData;
    }

    initPatient(patient, disease) {
        const p = {
            ...patient,
            ...patient[disease],
            absenceContact: patient.absenceContact || {},
            address: patient.address || {},
        };

        if (disease === 'nephro') {
            this.initPatientNephro(p);
        } else {
            this.initPatientCardio(p);
        }

        delete p[disease];

        return p;
    }

    initPatientNephro(patient) {
        this.conversionService.convertMedicalData(patient.medicalData);

        if (
            !['etape1', 'commonLaw', 'no', 'toComplete'].includes(
                patient.inclusion.type,
            )
        ) {
            patient.oldType = patient.inclusion.type;
        }

        patient.medicalData.mainDiagnosis =
            patient.medicalData.mainDiagnosis || {};
        patient.medicalData.significantEvent =
            patient.medicalData.significantEvent || {};
        patient.treatment = patient.treatment || {};
        patient.treatmentImmunosuppressant =
            patient.treatmentImmunosuppressant.map(
                ({
                    value,
                    frequency,
                    borne,
                    ...treatmentImmunosuppressant
                }) => ({
                    ...treatmentImmunosuppressant,
                    value: Number(value),
                    frequency: Number(frequency),
                    borne: {
                        min: Number(borne.min),
                        max: Number(borne.max),
                    },
                }),
            );

        patient.medicalData.racUnit =
            patient.medicalData.racUnit ||
            this.currentUser.biologicDataUnit.rac;
    }

    initPatientCardio(patient) {
        if (!patient.medicalData) {
            patient.medicalData = {};
        }

        if (!patient.medicalData.nyha) {
            patient.medicalData.nyha = '-';
        }

        if (patient.inclusion.type === 'toComplete') {
            patient.inclusion.type = 'commonLaw';
        }

        if (patient.inclusion.modalTemplate) {
            patient.inclusion.modalTemplate =
                !!this.patientService.checkPrescriptionTemplate(
                    patient.specialistId,
                );
        }

        if (
            !['etape4', 'commonLaw', 'no', 'toComplete'].includes(
                patient.inclusion.type,
            )
        ) {
            patient.oldType = patient.inclusion.type;
        }
        if (patient.inclusion.enableScale === undefined) {
            patient.inclusion.enableScale = this.currentUser.enableScale;
        }
        if (patient.inclusion.enableTensio === undefined) {
            patient.inclusion.enableTensio = this.currentUser.enableTensio;
        }
    }

    private initUser(): void {
        this.authService.currentUser
            .pipe(tap((user) => (this.currentUser = user)))
            .subscribe();
    }
}
