import { Injectable } from '@angular/core';
import { ErrorFormService } from '@services/form/error-form.service';
import { environment } from '@environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { forkJoin, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CreateCompanyService {
    company;
    specialist;

    constructor(
        private errorService: ErrorFormService,
        private http: HttpClient,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
    ) {}

    initCompanyCreation() {
        this.errorService.resetErrors();
        this.company = {
            address: {},
            phones: [],
        };

        return this.loadSpecialist();
    }

    createCompany(disease) {
        return this.http
            .post<any>(
                `${environment.apiUrl}/company`,
                { company: this.company },
                {
                    params: { disease },
                },
            )
            .pipe(
                tap(() => {
                    this.toastr.success(
                        this.translate.instant(
                            'create-company.identification.success',
                        ),
                    );
                    this.router.navigate(['company']);
                }),
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(() => err);
                }),
            );
    }

    loadSpecialist() {
        return forkJoin([
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/cardiologist`,
            ),
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/nephrologist`,
            ),
        ]).pipe(
            map(
                ([cardio, nephro]) =>
                    (this.specialist = {
                        cardio: cardio.users,
                        nephro: nephro.users,
                    }),
            ),
        );
    }

    checkIdentification(company) {
        return (
            company.companyName &&
            company.companyType &&
            company.address.completeAddress &&
            company.region &&
            company.serviceName &&
            company.phones.length
        );
    }
}
