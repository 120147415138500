import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { HttpResponse } from '@app/core';
import { Statistic, IconType } from '@ui/statistic';

import { StatisticResponse } from '../interfaces/statistic-response.interface';
import { StatisticsType } from '../types/statistics-types.type';

@Injectable()
export class PatientsStatisticService {
    private _iconsMap = {
        associated: 'included',
        adminValidation: 'adminsValidation',
        manageAlert: 'alertsManagement',
    };

    constructor(private readonly _http: HttpClient) {}

    public list<T>(type: StatisticsType): Observable<HttpResponse<T>> {
        const url = `${environment.apiUrl}/dashboard/patients/${type}`;
        return this._http.get<HttpResponse<T>>(url);
    }

    public transformStatisticResponse(
        response: StatisticResponse,
    ): Statistic[] {
        const statistics: Statistic[] = Object.entries(response).map(
            ([key, value]) => {
                const icon = this.getIconSynonym(key);
                const transformedIcon = (icon as IconType) ?? (key as IconType);

                return {
                    icon: transformedIcon,
                    label: key,
                    amount: value,
                };
            },
        );

        const orderedStatsLabels = [
            'toBill',
            'awaitingTraining',
            'active',
            'included',
        ];

        return statistics.sort(
            (a, b) =>
                orderedStatsLabels.indexOf(b.label) -
                orderedStatsLabels.indexOf(a.label),
        );
    }

    public getIconSynonym(key: string): string | undefined {
        return this._iconsMap[key];
    }
}
