import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-nephro',
    templateUrl: './nephro.component.svg',
    styleUrls: ['./nephro.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NephroComponent {
    @Input() disabled = true;
}
