import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GenerateCerfaService {
    constructor(private http: HttpClient) {}

    generateCerfaTsm(patientId, prescriptionDate, billDate) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/tsm`,
            { prescriptionDate, billDate },
            { responseType: 'blob' as 'json' },
        );
    }

    generateCerfaTsf(
        patientId,
        count,
        create,
        disease,
        tsfNumber,
        prescription,
    ) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/tsf`,
            {
                count,
                create,
                disease,
                tsfNumber,
                prescription,
            },
            { responseType: 'blob' as 'json' },
        );
    }
}
