import { Component, OnInit } from '@angular/core';
import { DocumentService, PrescriptionService } from '@services/documents';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';

@UntilDestroy()
@Component({
    selector: 'export-at',
    templateUrl: './export-at.component.html',
    styleUrls: ['./export-at.component.scss'],
})
export class ExportAtComponent implements OnInit {
    date;

    constructor(
        private prescriptionService: PrescriptionService,
        private readonly documentService: DocumentService,
        private bsModalRef: BsModalRef,
    ) {}

    ngOnInit(): void {
        this.date = new Date('01-06-2023');
    }
    onDateChange(date) {
        this.date = date;
    }

    exportAt() {
        this.documentService
            .getExportAT(this.date.getTime())
            .subscribe(() => this.bsModalRef.hide());
    }
}
