import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../security';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    currentUser;

    constructor(private http: HttpClient, private authService: AuthService) {
        this.initCurrentUser();
        this.getIdentityDocOptions().subscribe();
    }

    getConfiguration(code) {
        return this.http.get(`${environment.apiUrl}/configuration/${code}`);
    }

    getIdentityDocOptions() {
        return this.getConfiguration('identityDoc-options').pipe(
            map((res: any) => {
                return (
                    res.configuration?.value &&
                    JSON.parse(res.configuration?.value)
                );
            }),
        );
    }

    downloadInvitationFiles(type, docId) {
        return this.http
            .get<any>(
                `${environment.apiUrl}/configuration/invitation-file/${type}/${docId}`,
            )
            .pipe(
                map(({ token }) => {
                    window.open(
                        `${environment.apiUrl}/document/${token}/${this.currentUser._id}`,
                    );
                }),
            );
    }

    downloadStaticDocuments(type, docId) {
        return this.http
            .get<any>(
                `${environment.apiUrl}/configuration/static-document/${type}/${docId}`,
            )
            .pipe(
                map(({ token }) => {
                    window.open(
                        `${environment.apiUrl}/document/${token}/${this.currentUser._id}`,
                    );
                }),
            );
    }

    deleteInvitationFile(type, docId) {
        return this.http.delete<any>(
            `${environment.apiUrl}/configuration/invitation-file/${type}/${docId}`,
        );
    }

    deleteStaticDocument(type, docId) {
        return this.http.delete<any>(
            `${environment.apiUrl}/configuration/static-document/${type}/${docId}`,
        );
    }

    addStaticDocument(document, type) {
        const formData = new FormData();

        formData.append('type', type);
        formData.append('title', document.title);
        formData.append('disease', document.disease);
        if (document.file) {
            formData.append('file', document.file, document.file.name);
        }

        return this.http.post<any>(
            `${environment.apiUrl}/configuration/static-document`,
            formData,
        );
    }

    addInvitationFile(document, type) {
        const formData = new FormData();

        formData.append('type', type);
        formData.append('title', document.title);
        formData.append('file', document.file, document.file.name);

        return this.http.post<any>(
            `${environment.apiUrl}/configuration/invitation-file`,
            formData,
        );
    }

    private initCurrentUser(): void {
        this.authService.currentUser
            .pipe(tap((user) => (this.currentUser = user)))
            .subscribe();
    }

    updateIdentityDocOptions(value) {
        return this.http.post<any>(
            `${environment.apiUrl}/configuration/update-identitydoc-options`,
            {
                value,
            },
        );
    }

    getInseeCities(search) {
        return this.http.get<any>(`${environment.apiUrl}/insee-cities`, {
            params: { search },
        });
    }
}
