import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { forkJoin, throwError } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormPatientNephroService } from '@services/form/form-patient-nephro.service';
import { AuthService } from '@services/security';
import { FormatFormService } from './format-form.service';
import { ErrorFormService } from './error-form.service';

@Injectable({ providedIn: 'root' })
export class InclusionNephroService {
    patient;
    comment = '';
    currentUser;

    constructor(
        private http: HttpClient,
        private formatService: FormatFormService,
        private formPatientNephroService: FormPatientNephroService,
        private errorService: ErrorFormService,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {
        this.initUser();
    }

    initInclusion() {
        this.errorService.resetErrors();
        this.patient = {
            absenceContact: {},
            phones: {},
            signedConsent: true,
            medicalData: {
                nephroType: 'stage4',
                mainDiagnosis: {},
                significantEvent: {},
                creatinineUnit: this.currentUser.biologicDataUnit.creatinine,
                proteinuria24hUnit:
                    this.currentUser.biologicDataUnit.proteinuria24h,
                proteinuriaSampleUnit:
                    this.currentUser.biologicDataUnit.proteinuriaSample,
                racUnit: this.currentUser.biologicDataUnit.rac,
            },
            inclusion: {
                type: 'commonLaw',
            },
            treatment: {},
            address: {},
            prescriber: {},
            toComplete: false,
            identityStatus: 'provisional',
        };
        if (!this.authService.isAdmin) {
            this.patient.specialistId = this.currentUser._id;
        }
        return forkJoin([
            this.formPatientNephroService.loadCarestaff(),
            this.formPatientNephroService.loadImmunosuppressants(),
        ]);
    }

    includedPatient() {
        const formData = this.formatService.formatPatientNephro(this.patient);
        formData.append('comment', this.comment);
        return this.http
            .post<any>(`${environment.apiUrl}/patient`, formData, {
                params: { disease: 'nephro' },
            })
            .pipe(
                tap((result) => {
                    this.toastr.success(
                        this.translate.instant('inclusion.fastsuccess', {
                            patientId: result?.patient?._id,
                            userName: `${result?.patient?.firstName} ${result?.patient?.lastName}`,
                        }),
                        null,
                        {
                            enableHtml: true,
                            messageClass: 'toastLink',
                            closeButton: true,
                        },
                    );
                    this.router.navigate(['patient', result?.patient?._id]);
                }),
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(() => err);
                }),
            );
    }

    private initUser(): void {
        this.authService.currentUser
            .pipe(tap((user) => (this.currentUser = user)))
            .subscribe();
    }
}
