import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class PhotoAnalysisService {
    constructor(private http: HttpClient) {}

    downloadPhotoAnalysis(patientId, analysisDate, docId) {
        this.http
            .get<any>(
                `${environment.apiUrl}/patient/${patientId}/analysis/${analysisDate}/${docId}`,
                {
                    responseType: 'blob' as 'json',
                },
            )
            .pipe(tap((data) => saveAs(data, docId)))
            .subscribe();
    }
}
