<new-header h1="sidebar.dashboard">
    <ng-container *ngIf="authService.isSalesman">
        <div class="add-demo" (click)="addPatientDemo()">
            <img src="assets/image/man.svg" alt="man" />
            <span class="demo" translate="dashboard.demo"></span>
        </div>
    </ng-container>
    <ng-container *appCanAccess="['super']">
        <button
            id="exportAT"
            class="btn btn_accept export-at"
            translate="dashboard.export-at"
            (click)="exportAT()"
        ></button>
    </ng-container>
    <ng-container
        *appCanAccess="[
            'cardiologist',
            'nephrologist',
            'salesman',
            'super',
            'medical'
        ]"
    >
        <button
            id="addPatient"
            class="btn btn_accept add-patient"
            aria-expanded="false"
            data-bs-toggle="dropdown"
            translate="patients.add"
            (click)="addPatient()"
        ></button>
        <ul
            aria-labelledby="addPatient"
            class="dropdown-menu"
            *ngIf="!isSpecialist"
        >
            <li *ngFor="let item of actions$ | async">
                <a
                    (click)="item.action()"
                    [translate]="item.translate"
                    class="dropdown-item"
                ></a>
            </li>
        </ul>
    </ng-container>
</new-header>
<div class="main-page overflow-auto">
    <ng-container *ngIf="user$ | async as user">
        <div class="main-page__header">
            <h3 class="title">
                {{
                    'patient.log.message.dashboard'
                        | translate
                            : {
                                  firstName: isCompanyName()
                                      ? user?.companyName
                                      : user?.fullName
                              }
                        | titlecase
                }}
            </h3>

            <ng-container *ngIf="user?.toComplete === true">
                <div class="user-notification-wrapper">
                    <app-user-notification
                        [text]="
                            'dashboard.notifications.not-completed-user'
                                | translate
                        "
                    >
                        <button
                            class="btn user-notification-wrapper__button"
                            translate="dashboard.notifications.to-complete"
                            [routerLink]="['/profile']"
                        ></button>
                    </app-user-notification>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <div class="dashboard">
        <router-outlet></router-outlet>
    </div>

    <div class="dashboard d-md-none">
        <btn-action-mobile [actions]="actions$ | async"></btn-action-mobile>
    </div>
</div>
