import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@UntilDestroy()
@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.scss'],
})
export class ConfirmationModalComponent {
    public title: string;
    public message: string;
    public _date: string;
    public layout: 'default' | 'center' = 'default';
    public comment = '';
    public textZone = false;

    public set date(value: number) {
        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        this._date = `${day}/${month}/${date.getFullYear()}`;
    }

    public onConfirm = new EventEmitter();
    public onCancel = new EventEmitter();

    constructor(public bsModalRef: BsModalRef) {}

    confirm() {
        this.onConfirm.emit();
        this.bsModalRef.hide();
    }

    close() {
        this.onCancel.emit();
        this.bsModalRef.hide();
    }
}
