import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class DocumentService {
    currentUser;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {
        this.initUser();
    }

    getDocument(patient, disease, doc) {
        return this.http.get<any>(
            `${environment.apiUrl}/patient/${patient._id}/document/${doc._id}`,
            {
                params: { disease },
                responseType: 'blob' as 'json',
            },
        );
    }

    downloadDocument(patient, disease, doc) {
        return this.http
            .get<any>(
                `${environment.apiUrl}/patient/${patient._id}/document/${doc._id}`,
                {
                    params: { disease },
                    responseType: 'blob' as 'json',
                },
            )
            .pipe(tap((data) => saveAs(data, doc.filename)))
            .subscribe();
    }

    documentStatic() {
        return this.http
            .get<any>(`${environment.apiUrl}/configuration/static-documents`)
            .pipe(map((response) => response?.configuration?.consent || []));
    }

    downloadDocumentStatic(id: string) {
        return this.http
            .get<any>(
                `${environment.apiUrl}/configuration/static-document/consent/${id}`,
            )
            .pipe(
                map(({ token }) => {
                    window.open(
                        `${environment.apiUrl}/document/${token}/${this.currentUser._id}`,
                    );
                }),
            )
            .subscribe();
    }

    private initUser(): void {
        this.authService.currentUser
            .pipe(
                tap((user) => {
                    this.currentUser = user;
                }),
            )
            .subscribe();
    }

    getExport(id, disease, anon) {
        const url = `${environment.apiUrl}/document/export-patient/${id}/${this.currentUser._id}/${disease}/${anon}`;
        return this.http.get<any>(url, { responseType: 'blob' as 'json' });
    }

    deleteDocument(patient, docId, disease) {
        return this.http.delete<any>(
            `${environment.apiUrl}/patient/${patient._id}/document/${docId}/${disease}`,
        );
    }

    exportBilling(start, end) {
        const disease =
            this.currentUser.type === 'cardiologist' ? 'cardio' : 'nephro';
        const url = `${environment.apiUrl}/document/export-billing/${this.currentUser._id}/${disease}`;
        const today = new Date().getTime();
        return this.http
            .get<any>(url, {
                params: { start, end },
                responseType: 'blob' as 'json',
            })
            .pipe(
                tap((response) => {
                    const blob = new Blob([response], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    const filename = `Extraction facturation du ${format(
                        today,
                        'dd-MM-yyyy',
                    )}.xlsx`;
                    saveAs(blob, filename);
                }),
                catchError((error) => {
                    if (error?.status === 404) {
                        this.toastr.info(
                            this.translate.instant(
                                'patient.modal.export-excel.noBillingsFound',
                            ),
                        );
                    }
                    return of(error);
                }),
            );
    }

    getExportAT(date) {
        const today = new Date().getTime();
        return this.http
            .get<any>(`${environment.apiUrl}/document/export-at`, {
                params: { date },
                responseType: 'blob' as 'json',
            })
            .pipe(
                tap((response) => {
                    const blob = new Blob([response], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    const filename = `Export-AT-${format(
                        today,
                        'dd-MM-yyyy',
                    )}-${format(date, 'dd-MM-yyyy')}.xlsx`;
                    saveAs(blob, filename);
                }),
            );
    }
}
