import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({ providedIn: 'root' })
export class ModalFullService {
    constructor(private modalService: BsModalService) {}

    open(component: TemplateRef<any> | { new (...args: any[]) }, options = {}) {
        const initialState = {
            initialState: options,
            class: 'modal-full',
            width: '100%',
        };

        return this.modalService.show(component, initialState);
    }
}
