import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { forkJoin, throwError } from 'rxjs';

import { FormPatientNephroService } from '@services/form/form-patient-nephro.service';
import { SidebarService } from '@ui/sidebar';
import { addDays } from 'date-fns';
import { AuthService } from '../security';
import { PatientService } from '../patient';
import { ErrorFormService } from './error-form.service';
import { FormatFormService } from './format-form.service';

@Injectable({ providedIn: 'root' })
export class ReinclusionNephroService {
    patient;
    comment = '';

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private patientService: PatientService,
        private errorService: ErrorFormService,
        private router: Router,
        private formatService: FormatFormService,
        private formPatientNephroService: FormPatientNephroService,
        private sidebarService: SidebarService,
    ) {}

    initReinclusion(id) {
        this.errorService.resetErrors();

        const promises = [
            this.initPatient(id),
            this.formPatientNephroService.loadCarestaff(),
            this.formPatientNephroService.loadImmunosuppressants(),
        ];

        return forkJoin(promises);
    }

    initPatient(id) {
        return this.patientService.getPatient(id, [], 'nephro').pipe(
            tap((patient) => {
                this.patient = this.formatService.initPatient(
                    patient,
                    'nephro',
                );
                this.patient.inclusion.prescription = false;
                this.patient.inclusion.type = 'commonLaw';
                this.patient.inclusion.prescriptionFileName = '';
                this.patient.inclusion.prescriptionFileType = '';
                this.patient.inclusion.startDate = addDays(
                    this.patient.inclusion.endDate,
                    1,
                ).getTime();
            }),
        );
    }

    canSavePatient() {
        return (
            this.formPatientNephroService.checkInclusionModePatient(
                this.patient,
            ) &&
            this.formPatientNephroService.checkMedicalDataPatient(
                this.patient,
            ) &&
            (!this.authService.isAdmin ||
                this.formPatientNephroService.checkCarestaffPatient(
                    this.patient,
                ))
        );
    }

    reinclusionPatient() {
        if (this.patient.demo) {
            this.sidebarService.show();
            this.router.navigate(['patient', this.patient._id]);

            return;
        }

        const formData = this.formatService.formatPatientNephro(this.patient);
        formData.append('comment', this.comment);

        return this.reinclusionPatientRequest(formData).pipe(
            tap(() => {
                this.sidebarService.show();
                this.router.navigate(['patient', this.patient._id]);
            }),
        );
    }

    reinclusionPatientRequest(formData) {
        return this.http
            .put<any>(
                `${environment.apiUrl}/patient/${this.patient._id}/reinclusion`,
                formData,
                {
                    params: { disease: 'nephro' },
                },
            )
            .pipe(
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(() => err);
                }),
            );
    }
}
