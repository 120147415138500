<div [formGroup]="form" class="btn-group" role="group">
    <ng-container *ngFor="let fieldNumber of fieldsNumbersArray; index as i">
        <input
            autocomplete="off"
            class="btn-check"
            [id]="field + fieldNumber"
            [name]="field"
            type="radio"
            [value]="fieldNumber"
            [formControlName]="field"
        />
        <label
            [title]="tootipConfig ? (tootipConfig[i] | translate) : ''"
            class="btn btn-radio btn-outline-primary"
            [for]="field + fieldNumber"
            >{{ fieldNumber }}</label
        >
    </ng-container>
</div>
