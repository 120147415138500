import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@services/security';
import { Observable } from 'rxjs';

@Injectable()
export class CanLoadDashboardGuard implements CanLoad {
    constructor(public authService: AuthService) {}
    canLoad(
        route: Route,
        segments: UrlSegment[],
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return !!this.authService.getCurrentUser();
    }
}
