<div>
    <div class="modal-body">
        <h4 class="fw-bold" translate="inclusion.helpNewCard"></h4>
        <p translate="inclusion.takeTheRelay"></p>
        <div class="d-flex flex-column flex-md-row-reverse btn-submit p-2">
            <button
                (click)="include()"
                class="btn btn-primary btn-next mt-2 loading-btn fw-bold small text-uppercase"
                translate="inclusion.finishInclude"
            ></button>
            <button
                (click)="includeAndComplete()"
                class="btn btn-default btn-next mt-2 loading-btn fw-bold secondary small text-uppercase background-fix"
                translate="inclusion.finishIncludeAndComplete"
            ></button>
        </div>
    </div>
</div>
