import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.svg',
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
    @Input()
    public active = true;
}
