<img
    class="package-icon"
    (click)="openModal(template)"
    src="../assets/image/info2.svg"
    alt=""
    srcset=""
/>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Evaluation du niveau de forfait</h4>
        <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="modalRef?.hide()"
        >
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body px-4 py-3">
        <p>
            Les modulations prévues sur le fondement des critères mentionnés aux
            1° et 2° de l'article L. 162-54 et au II de l'article R. 162-95 du
            code de la sécurité sociale, applicables aux tarifs du forfait
            opérateur de niveau 2, donnent lieu aux forfaits majorés suivants :
            <br />
            forfait majoré de niveau 1 : 56 € ;
            <br />
            forfait majoré de niveau 2 : 70 €.
        </p>

        <p>
            Ce forfait majoré est facturable au terme de chaque période d'un
            mois de réalisation de la télésurveillance.La facturation pour un
            même patient des forfaits majorés prévus par le présent article ne
            peut excéder une période sans interruption de : 6 mois renouvelables
            une fois pour l'activité de télésurveillance médicale de
            l'insuffisance cardiaque inscrite sous forme générique, sur la liste
            prévue à l'article L. 162-52 du code de la sécurité sociale ;
            Conformément à l'article R. 162-100 du code de la sécurité sociale
            limitant la prescription d'une activité de télésurveillance à un an,
            la facturation du forfait opérateur et de ses éventuelles
            modulations, en l'absence d'une nouvelle prescription, ne peut
            excéder une période d'un an sans interruption.
        </p>

        <u> Critères d’éligibilité au forfait majoré de niveau 1 - 56€/mois </u>

        <ul>
            <li>
                Patients âgés de 80 ans ou plus atteints d'au moins une
                comorbidité prévue au 2° du III du présent article ;
            </li>
            <li>
                Patients âgés de moins de 80 ans atteints d'au moins deux
                comorbidités prévues au 2° du III du présent article ;
            </li>
            <li>
                Patients hospitalisés dans les 30 derniers jours pour
                décompensation cardiaque.
            </li>
        </ul>

        <u> Critères d’éligibilité au forfait majoré de niveau 2 - 70€/mois </u>

        <ul>
            <li>
                Patients âgés de 80 ans ou plus atteints d'au moins deux
                comorbidités prévues au 2° du III du présent article ;
            </li>
            <li>
                Patients âgés de moins de 80 ans atteints d'au moins trois
                comorbidités prévues au 2° du III du présent article ;
            </li>
            <li>Patients ayant des pathologies ou parcours spécifiques :</li>
            <li>Victime d'un choc cardiogénique dans les 6 derniers mois ;</li>
            <li>
                Inscrit sur la liste d'attente pour transplantation cardiaque ;
            </li>
            <li>Syndrome cardio-rénal et en attente de dialyse ;</li>
            <li>
                Insuffisance cardiaque réfractaire et dont le pronostic vital
                est inférieur à une année ;
            </li>
            <li>
                Cardiomyopathie restrictive, en sortie d'hospitalisation avec
                insuffisance cardiaque correspondant a minima au stade II NYHA ;
            </li>
            <li>
                Amylose cardiaque, en sortie d'hospitalisation avec insuffisance
                cardiaque correspondant a minima au stade II NYHA.
            </li>
        </ul>

        Liste des comorbidités:

        <ul>
            <li>
                Cancer sous traitement systémique ou traitement par
                radiothérapie
            </li>
            <li>
                Insuffisance Rénale chronique (uniquement si DFG < 45
                ml/min/1,73m²)
            </li>
            <li>
                Anémie sévère (Hb < 10g/dL) ou carence martiale sévère
                (Ferritine< 100mg/L ou ferritine nécessitant supplémentation
                comprise entre 100 et 299 mg/mL+ CST < 20%)
            </li>
            <li>
                Dénutrition sévère associée à anémie sidéroblastique ou une
                malnutrition protéino-énergétique grave (IMC < ou=à 17, ou perte
                de 10% du poids en 1 mois, ou 15% du poids en 6 mois ou albumine
                < 30 mg/L ou pro-albumine < 0,15 g/L)
            </li>
        </ul>
    </div>
</ng-template>
