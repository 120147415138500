import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@services/security';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        public authService: AuthService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const currentUser = this.authService.getCurrentUser();
        const isApiUrl =
            request.url.startsWith(environment.apiUrl) ||
            request.url.startsWith(environment.pmpApiUrl);
        if (currentUser?.token && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    'X-UserToken': `${currentUser.token}`,
                },
            });
        }

        return next.handle(request).pipe(
            tap(() => (this.authService.lastRequest = new Date())),
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        if (err.error?.message) {
                            this.toastr.error(
                                this.translate.instant(
                                    `errors.${err.error.message}`,
                                ),
                            );
                        }
                    } else {
                        this.authService.logout(true);
                    }
                } else {
                    this.authService.logout(true);
                }
                return throwError(err);
            }),
        );
    }
}
