import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { RedirectGuard } from '@services/guard';
import { RedirectAlertGuard } from '@services/guard/redirectAlert.guard';

import { environment } from '@environments/environment';
import { ManageAlertComponent } from '@app/manage-alert';
import { CanLoadDashboardGuard } from './dashboard';

const routes: Routes = [
    {
        path: 'dashboard',
        data: { state: 'dashboard' },
        canLoad: [CanLoadDashboardGuard],
        loadChildren: () => import('./dashboard').then((m) => m.DashboardModule),
    },
    {
        path: 'alerts-stats',
        data: { state: 'alerts-stats' },
        loadChildren: () =>
            import('./alerts-stats').then((m) => m.AlertsStatsModule),
    },
    {
        path: 'alerts-technical',
        data: { state: 'alerts-technical' },
        loadChildren: () =>
            import('./alerts-technical').then((m) => m.AlertsTechnicalModule),
    },
    {
        path: 'alerts',
        canActivate: [RedirectGuard],
        data: { externalUrl: `${environment.psa}/alerts` },
        loadChildren: () =>
            import('./alerts-manager').then((m) => m.AlertsManagerModule),
    },
    {
        path: 'alerts-manager/:type',
        canActivate: [RedirectGuard],
        component: ManageAlertComponent,
        data: { externalUrl: `${environment.psa}/alerts-manager` },
    },
    {
        path: 'manage-alert/:id',
        canActivate: [RedirectAlertGuard],
        component: ManageAlertComponent,
        data: { externalUrl: `${environment.psa}/manage-alert` },
    },
    {
        path: 'carestaff',
        data: { state: 'carestaff' },
        loadChildren: () => import('./carestaff').then((m) => m.CarestaffModule),
    },

    {
        path: 'patients',
        loadChildren: () => import('./patients').then((m) => m.PatientsModule),
    },
    {
        path: 'patient',
        loadChildren: () => import('./patient').then((m) => m.PatientModule),
    },
    {
        path: 'admin-follow',
        loadChildren: () =>
            import('./admin-follow').then((m) => m.AdminFollowModule),
    },
    {
        path: 'users',
        loadChildren: () => import('./users').then((m) => m.UsersModule),
    },
    {
        path: 'user',
        loadChildren: () => import('./user').then((m) => m.UserModule),
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./current-user').then((m) => m.CurrentUserModule),
    },
    {
        path: 'billing',
        loadChildren: () => import('./billing').then((m) => m.BillingModule),
    },
    {
        path: 'config',
        loadChildren: () => import('./config').then((m) => m.ConfigModule),
    },
    {
        path: 'device',
        loadChildren: () => import('./device').then((m) => m.DeviceModule),
    },
    {
        path: 'edition',
        loadChildren: () => import('./edition').then((m) => m.EditionModule),
    },
    {
        path: 'edition-nephro',
        loadChildren: () =>
            import('./edition-nephro').then((m) => m.EditionNephroModule),
    },
    {
        path: 'inclusion-nephro',
        loadChildren: () =>
            import('./inclusion-nephro').then((m) => m.InclusionNephroModule),
    },
    {
        path: 'reinclusion',
        loadChildren: () =>
            import('./reinclusion').then((m) => m.ReinclusionModule),
    },
    {
        path: 'reinclusion-nephro',
        loadChildren: () =>
            import('./reinclusion-nephro').then((m) => m.ReinclusionNephroModule),
    },
    {
        path: 'ins/:disease/:id',
        loadChildren: () =>
            import('./insi-consult').then((m) => m.InsiConsultModule),
    },
    {
        path: 'create-company',
        loadChildren: () =>
            import('./create-company').then((m) => m.CreateCompanyModule),
    },
    {
        path: 'company',
        loadChildren: () => import('./company').then((m) => m.CompanyModule),
    },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [
        {
            provide: CanLoadDashboardGuard,
        },
    ],
})
export class AppRoutingModule {}
