import { Injectable } from '@angular/core';
import { AuthService } from '@services/security';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { tap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Injectable({
    providedIn: 'root',
})
export class DatepickerLanguageService {
    constructor(
        private authService: AuthService,
        private readonly localeService: BsLocaleService,
    ) {
        defineLocale('fr', frLocale);
    }

    setDatePickerLanguage() {
        this.authService.currentUser
            .pipe(
                tap((user) => {
                    if (user.language === 'fr') {
                        this.localeService.use('fr');
                    } else {
                        this.localeService.use('en');
                    }
                }),
            )
            .subscribe();
    }
}
