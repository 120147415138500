import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class PrescriptionService {
    biologicData;
    currentUser;
    constructor(private http: HttpClient, private authService: AuthService) {
        this.initUser();
        this.biologicData = {
            bloodHome: false,
            bloodLab: false,
            NFS: false,
            CRP: false,
            uree: false,
            uricemie: false,
            creatinine: false,
            creatininemie: false,
            ionogramme: false,
            TSH: false,
            T4: false,
            glycemie: false,
            lipidique: false,
            BNP: false,
            NTproBNP: false,
            troponine: false,
            alcalines: false,
            albuminurie: false,
            albuminurie24h: false,
            other: false,
        };
    }

    downloadPrescriptionCurrent(patient, disease) {
        this.downloadPrescription(
            patient,
            disease,
            patient[disease].inclusion.prescriptionFileName,
        );
    }

    downloadPrescription(patient, disease, docId) {
        this.http
            .get<any>(
                `${environment.apiUrl}/patient/${patient._id}/prescription/${docId}`,
                {
                    params: { disease },
                    responseType: 'blob' as 'json',
                },
            )
            .pipe(tap((data) => saveAs(data, 'ordonnance')))
            .subscribe();
    }

    deletePrescription(patient, docId) {
        return this.http.delete<any>(
            `${environment.apiUrl}/patient/${patient._id}/prescription/${docId}`,
        );
    }

    downloadPrescriptionTemplate(id, type) {
        return this.http
            .get<any>(
                `${environment.apiUrl}/user/${id}/prescriptionTemplate/${type}`,
            )
            .pipe(
                map(({ token }) => {
                    window.open(
                        `${environment.apiUrl}/document/${token}/${this.currentUser._id}`,
                    );
                }),
            );
    }
    deletePrescriptionTemplate(id) {
        return this.http.delete<any>(
            `${environment.apiUrl}/user/${id}/prescriptionTemplate`,
        );
    }
    downloadAuthorization(id, docId) {
        return this.http
            .get<any>(`${environment.apiUrl}/user/${id}/authorization/${docId}`)
            .pipe(
                map(({ token }) => {
                    window.open(
                        `${environment.apiUrl}/document/${token}/${this.currentUser._id}`,
                    );
                }),
            );
    }
    deleteAuthorization(id, docId) {
        return this.http.delete<any>(
            `${environment.apiUrl}/user/${id}/authorization/${docId}`,
        );
    }
    rejectPrescriptionTemplate(id) {
        return this.http.delete<any>(
            `${environment.apiUrl}/user/${id}/prescriptionTemplateReject`,
        );
    }

    deletePrescriptionAuthorization(id) {
        return this.http.delete<any>(
            `${environment.apiUrl}/user/${id}/prescriptionAuthorization`,
        );
    }
    private initUser(): void {
        this.authService.currentUser
            .pipe(
                tap((user) => {
                    this.currentUser = user;
                }),
            )
            .subscribe();
    }
    generatePrescription(patientId, prescriptionDate, initWeight) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/prescription`,
            { prescriptionDate, initWeight },
            { responseType: 'blob' as 'json' },
        );
    }
    generatePrescriptionCustom(patientId, prescriptionDate, bodyText) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/prescriptionCustom`,
            { prescriptionDate, bodyText },
            { responseType: 'blob' as 'json' },
        );
    }
    getBiologicData() {
        return this.biologicData;
    }
    generatePrescriptionBiologicData(
        prescriptionDate,
        patientId,
        biologicData,
    ) {
        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patientId}/prescriptionBiologicData`,
            { prescriptionDate, ...biologicData },
            { responseType: 'blob' as 'json' },
        );
    }

    renewPrescription(patient) {
        return this.http.get<any>(
            `${environment.apiUrl}/patient/${patient._id}/renewPrescription`,
        );
    }

    uploadPrescription(patient, disease, prescription, inclusionId) {
        const formData: FormData = new FormData();
        formData.append('prescription', prescription, prescription.name);

        return this.http.post<any>(
            `${environment.apiUrl}/patient/${patient._id}/prescription/${inclusionId}`,
            formData,
            { params: { disease } },
        );
    }
}
