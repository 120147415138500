import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { differenceInMinutes } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public currentUser: Observable<any>;
    private currentUserSubject: BehaviorSubject<any>;

    public isAdmin: boolean;
    public isSpecialist: boolean;
    public isUserMedical: boolean;
    public isAdminMedical: boolean;
    public isAdminAccounting: boolean;
    public isSuperAdmin: boolean;
    public isNephro: boolean;
    public isCardio: boolean;
    public isCpa: boolean;
    public isSalesman: boolean;
    public isIde: boolean;
    public isInstaller: boolean;
    public isTransplantCenter: boolean;
    public isDoctor: boolean;
    public isTherapeuticCoach: boolean;
    public isAnonymizer: boolean;
    public canEditMedicalData: boolean;
    public canEditPatientData: boolean;
    public lastRequest: Date;

    constructor(
        private cookieService: CookieService,
        private router: Router,
        private http: HttpClient,
    ) {
        const user = this.cookieService.getObject('currentUser');
        if (!user) {
            this.logout();
        } else {
            this.currentUserSubject = new BehaviorSubject<any>(user);
            this.currentUser = this.currentUserSubject.asObservable();
            this.updateRoleStates(user);
        }
    }

    getCurrentUser() {
        return this.currentUserSubject?.value;
    }

    setCurrentUser(user) {
        /**
         * We need to complete given user with existing user because we have some cases
         * where we'd lose user properties.
         * For instance, if user login with PSC, there is `user.isPSC` boolean property stored in
         * cookies (then in the subject) => but after updating his profile (i.e. default units),
         * the returned updated user (from backend) doesn't own the `isPSC` property.
         */

        const completeUser = this.getBasicUserForCookies({
            ...this.getCurrentUser(),
            ...user,
        });
        this.currentUserSubject.next(completeUser);

        const expires = new Date();
        expires.setTime(expires.getTime() + 8 * 60 * 60 * 1000);
        this.cookieService.putObject('currentUser', completeUser, {
            domain: environment.cookieDomain,
            sameSite: 'lax',
            expires,
        });
        this.updateRoleStates(completeUser);
    }

    getBasicUserForCookies(user) {
        const {
            _id,
            token,
            firstName,
            lastName,
            fullName,
            companyName,
            type,
            supervisorDoctor,
            adminType,
            sharedProfile,
            isPSC,
            prescriptionTemplateAuthorization,
            prescriptionTemplate,
            isAnonymizer,
            language,
            biologicDataUnit,
            authorizeBiologicData,
            enableScale,
            enableTensio,
        } = user;

        return {
            _id,
            token,
            firstName,
            lastName,
            fullName,
            companyName,
            type,
            supervisorDoctor,
            adminType,
            sharedProfile,
            isPSC,
            isAnonymizer,
            prescriptionTemplateAuthorization,
            prescriptionTemplate,
            language,
            biologicDataUnit,
            authorizeBiologicData,
            enableScale,
            enableTensio,
        };
    }

    logoutFromPSC(token: string): Observable<void> {
        return this.http.post<void>(`${environment.authApiUrl}/psc/logout`, {
            token,
        });
    }

    logout(expire = false) {
        const { token } = this.getCurrentUser() || {};

        this.logoutFromPSC(token).subscribe();

        let url = `${environment.authfrontURL}/?returnUrl=${window.location.href}`;

        if (expire) {
            url = `${url}&expire=true`;
        }

        this.cookieService.remove('currentUser');
        document.location.href = url;
    }

    private updateRoleStates(user) {
        this.isAdmin = user.type === 'admin';
        this.isSpecialist = ['cardiologist', 'nephrologist'].includes(
            user.type,
        );
        this.isAdminMedical = ['super', 'medical'].includes(user.adminType);
        this.isAdminAccounting = user.adminType === 'accounting';
        this.isSuperAdmin = user.adminType === 'super';
        this.isNephro = user.type === 'nephrologist';
        this.isCardio = user.type === 'cardiologist';
        this.isCpa = user.type === 'cardiologist' && user.sharedProfile;
        this.isSalesman = user.type === 'salesman';
        this.isIde = user.type === 'ide';
        this.isInstaller = user.type === 'installer';
        this.isTransplantCenter = user.type === 'transplant_center';
        this.isDoctor = user.type === 'doctor';
        this.isTherapeuticCoach = user.type === 'therapeutic_coach';
        this.isAnonymizer = user.isAnonymizer;

        this.isUserMedical =
            this.isSpecialist ||
            this.isAdminMedical ||
            this.isTherapeuticCoach ||
            this.isDoctor ||
            this.isIde ||
            this.isSalesman ||
            this.isTransplantCenter;

        this.canEditMedicalData =
            this.isAdminMedical || this.isSpecialist || this.isSalesman;
        this.canEditPatientData =
            this.isAdmin || this.isSpecialist || this.isSalesman;
    }

    refreshToken() {
        const { token } = this.getCurrentUser() || {};
        return this.http
            .post<any>(`${environment.apiUrl}/sso/reauthenticate`, { token })
            .pipe(
                tap(({ user }) => {
                    this.setCurrentUser(user);
                }),
            );
    }

    setLastKeyDownTime() {
        if (differenceInMinutes(new Date(), this.lastRequest) >= 10) {
            this.lastRequest = new Date();
            this.refreshToken().subscribe();
        }
    }
}
