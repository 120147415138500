import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatepickerLanguageService } from '@services/datepicker-language';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LoaderModule } from '@ui/loader';
import { SidebarModule } from '@ui/sidebar';
import { ManageAlertComponent } from '@app/manage-alert';
import { RatingModule } from '@ui/rating/rating.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '@environments/environment';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing-module';

import { AppComponent } from './app.component';

const config: SocketIoConfig = {
    url: environment.pmpSockets,
    options: {
        transports: ['websocket'],
        autoConnect: false,
    },
};

@NgModule({
    declarations: [AppComponent, ManageAlertComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        SidebarModule,
        LoaderModule,
        RatingModule,
        BsDatepickerModule.forRoot(),
        SocketIoModule.forRoot(config),
    ],
    providers: [DatepickerLanguageService, BsLocaleService],
    bootstrap: [AppComponent],
    exports: [RatingModule],
})
export class AppModule {}
