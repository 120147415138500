<div class="user-notification" [appNotificationLevelStyles]="level">
    <app-info
        [active]="level === 'white'"
        class="user-notification__icon"
    ></app-info>

    <div class="user-notification__text">{{ text }}</div>
    <div class="user-notification__actions">
        <ng-content></ng-content>
    </div>

    <ng-container *ngIf="close">
        <app-close class="close"></app-close>
    </ng-container>
</div>
