import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-close',
    templateUrl: './close.component.svg',
    styleUrls: ['./close.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseComponent {
    @Input()
    public active = false;
}
