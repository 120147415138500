import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { forkJoin, throwError } from 'rxjs';

import { FormPatientCardioService } from '@services/form/form-patient-cardio.service';
import { addDays } from 'date-fns';
import { PatientService } from '../patient';
import { ErrorFormService } from './error-form.service';
import { FormatFormService } from './format-form.service';

@Injectable({ providedIn: 'root' })
export class ReinclusionCardioService {
    patient;
    comment = '';
    public returnUrl: string | null = null;
    constructor(
        private http: HttpClient,
        private patientService: PatientService,
        private formPatientCardioService: FormPatientCardioService,
        private errorService: ErrorFormService,
        private router: Router,
        private formatService: FormatFormService,
    ) {}

    initReinclusion(id, returnUrl?) {
        this.errorService.resetErrors();
        this.returnUrl = returnUrl;
        return forkJoin([
            this.initPatient(id),
            this.formPatientCardioService.loadCarestaff(),
        ]);
    }

    initPatient(id) {
        return this.patientService.getPatient(id, [], 'cardio', true).pipe(
            tap((patient) => {
                this.patient = this.formatService.initPatient(
                    patient,
                    'cardio',
                );
                if (!this.patient.lastWeight) {
                    this.patient.medicalData.initWeight = '';
                } else {
                    this.patient.medicalData.initWeight =
                        this.patient.lastWeight;
                }
                this.patient.inclusion.prescription = false;
                this.patient.inclusion.prescriptionFileName = '';
                this.patient.inclusion.prescriptionFileType = '';
                this.patient.inclusion.type = 'commonLaw';
                this.patient.inclusion.startDate = addDays(
                    this.patient.inclusion.endDate,
                    1,
                ).getTime();
                this.patient.inclusion.billingPackage = 'tvb';
            }),
        );
    }

    reinclusionFastPatient() {
        const formData = this.formatService.formatPatientFast(this.patient);
        formData.append('comment', this.comment);
        return this.reinclusionPatientRequest(formData);
    }

    reinclusionPatient() {
        const formData = this.formatService.formatPatientCardio(this.patient);
        return this.reinclusionPatientRequest(formData).pipe(
            tap(() => this.router.navigate(['patient', this.patient._id])),
        );
    }

    reinclusionPatientRequest(formData) {
        return this.http
            .put<any>(
                `${environment.apiUrl}/patient/${this.patient._id}/reinclusion`,
                formData,
                {
                    params: { disease: 'cardio' },
                },
            )
            .pipe(
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(() => err);
                }),
            );
    }

    canSavePatientFast() {
        return (
            this.patient.specialistId &&
            this.patient.medicalData.currentRate &&
            this.formPatientCardioService.checkCarestaffPatient(this.patient)
        );
    }
}
