import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
    @Input()
    field;
    @Input()
    tootipConfig = undefined;
    @Input()
    form: any;
    @Input()
    min = 1;
    @Input()
    max = 5;

    fieldsNumbersArray: number[];

    ngOnInit(): void {
        this.fieldsNumbersArray = Array(this.max - this.min + 1)
            .fill(1)
            .map((x, i) => i + 1);
    }
}
