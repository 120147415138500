import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderModule } from '@ui/header';
import { BtnActionMobileModule } from '@ui/btn-action-mobile';
import { UserNotificationModule } from '@ui/user-notification';
import { CanAccessModule } from '@utils/can-access';
import { ModalRightService } from '@services/modal';

import { LoadingButtonModule } from '@ui/loading-button/loading-button.module';
import { ExportAtComponent } from '@app/dashboard/modals/export-at/export-at.component';
import { DocumentService } from '@services/documents';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalFullService } from '@services/modal/modal-full.service';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

import { AlertsService } from './services/alerts.service';
import { PatientsStatisticService } from './services/patients-statistic.service';
import { RouteDetectorService } from './services/route-detector.service';

@NgModule({
    declarations: [DashboardComponent, ExportAtComponent],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        HeaderModule,
        CanAccessModule,
        BtnActionMobileModule,
        UserNotificationModule,
        ModalModule.forChild(),
        TranslateModule.forChild(),
        LoadingButtonModule,
        BsDatepickerModule,
    ],
    providers: [
        RouteDetectorService,
        AlertsService,
        PatientsStatisticService,
        ModalRightService,
        DocumentService,
        ModalFullService,
    ],
})
export class DashboardModule {}
