import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { HttpResponse } from '@app/core';
import { Diseases } from '@ui/diseases-switcher';

import { AlertsResponse } from '../interfaces/alerts-response.interface';
import { AlertType } from '../types/alert-types.type';

@Injectable()
export class AlertsService {
    constructor(private readonly _http: HttpClient) {}

    public list(
        type: AlertType,
        disease: Diseases = 'cardio',
    ): Observable<HttpResponse<AlertsResponse>> {
        const url = `${environment.apiUrl}/dashboard/alerts/${type}`;
        const params = new HttpParams().append('disease', disease);

        return this._http.get<HttpResponse<AlertsResponse>>(url, {
            params,
        });
    }
}
