<div [class]="displayBtn" class="btn-action">
    <ng-container *ngIf="actions.length === 1">
        <button
            (click)="actions[0].action()"
            class="btn btn-primary dropdown-toggle"
            id="addPatientBtn"
            type="button"
        >
            +
        </button>
    </ng-container>
    <ng-container *ngIf="actions.length > 1">
        <button
            aria-expanded="false"
            class="btn btn-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            id="addPatient"
            type="button"
        >
            +
        </button>
    </ng-container>

    <ul aria-labelledby="addPatient" class="dropdown-menu">
        <ng-container *ngFor="let item of actions">
            <li>
                <a
                    (click)="item?.action()"
                    [translate]="item.translate"
                    class="dropdown-item"
                ></a>
            </li>
        </ng-container>
    </ul>
</div>
