import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/security';

@Injectable()
export class RouteDetectorService {
    constructor(
        private readonly router: Router,
        public authService: AuthService,
    ) {}

    public detectRoute(): void {
        const user = this.authService.getCurrentUser();

        // TODO: find best implementation for route detection by user role
        if (!user?.type) {
            this.router.navigate(['/dashboard/unknown']);
            return;
        }

        if (this.authService.isSpecialist) {
            this.router.navigate(['/dashboard/specialist']);
            return;
        }

        if (this.authService.isSalesman) {
            this.router.navigate(['/dashboard/salesman']);
            return;
        }

        if (this.authService.isDoctor) {
            this.router.navigate(['/dashboard/doctor']);
            return;
        }

        if (this.authService.isIde) {
            this.router.navigate(['/dashboard/observer']);
            return;
        }

        if (this.authService.isAdmin && !this.authService.isAdminAccounting) {
            this.router.navigate(['/dashboard/admin']);
            return;
        }

        if (this.authService.isAdminAccounting) {
            this.router.navigate(['/dashboard/accounting']);
            return;
        }

        if (this.authService.isInstaller) {
            this.router.navigate(['/dashboard/installer']);
            return;
        }

        if (this.authService.isTherapeuticCoach) {
            this.router.navigate(['/dashboard/therapeutic-coach']);
            return;
        }
        if (this.authService.isTransplantCenter) {
            this.router.navigate(['/dashboard/transplant-center']);
            return;
        }

        this.router.navigate(['/dashboard/unknown']);
    }
}
