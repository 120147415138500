import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { tap, map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    getUser(id) {
        return this.http
            .get<any>(`${environment.apiUrl}/user/${id}`)
            .pipe(map(({ user }) => user));
    }

    updateUser(id, body, page) {
        return this.http.put<any>(
            `${environment.apiUrl}/user/${id}/${page}`,
            body,
        );
    }

    updateCurrentUser(id, body, page) {
        return this.updateUser(id, body, page).pipe(
            tap(({ user }) => this.authService.setCurrentUser(user)),
        );
    }

    changePassword(currentpassword, newpassword, confirmpassword) {
        const body = { currentpassword, newpassword, confirmpassword };
        return this.http.post<any>(
            `${environment.apiUrl}/user/new-password`,
            body,
        );
    }

    loadCarestaff() {
        return forkJoin([
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/cardiologist`,
            ),
            this.http.get<any>(`${environment.apiUrl}/users/simple/doctor`),
            this.http.get<any>(`${environment.apiUrl}/users/simple/ide`),
            this.http.get<any>(
                `${environment.apiUrl}/users/simple/therapeutic_coach`,
            ),
            this.http.get<any>(`${environment.apiUrl}/users/simple/installer`),
        ]).pipe(
            map(([cardio, doctor, ide, therapeuticCoach, installer]) => ({
                cardio: cardio.users,
                doctor: doctor.users,
                ide: ide.users,
                therapeuticCoach: therapeuticCoach.users,
                installer: installer.users,
            })),
        );
    }

    uploadPrescriptionTemplate(prescription, id) {
        const formData = new FormData();
        formData.append(
            'droppedFile',
            prescription.file,
            prescription.file.name,
        );

        return this.http.post<any>(
            `${environment.apiUrl}/user/${id}/prescriptionTemplate`,
            formData,
        );
    }

    uploadAuthorization(authorization, id) {
        const formData = new FormData();

        formData.append(
            'droppedFile',
            authorization.file,
            authorization.file.name,
        );

        return this.http.post<any>(
            `${environment.apiUrl}/user/${id}/prescriptionAuthorization`,
            formData,
        );
    }

    getInstallers() {
        return this.http.get<any>(
            `${environment.apiUrl}/users/simple/installer`,
        );
    }
}
