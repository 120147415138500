<div class="modal-header" [class.center]="layout === 'center'">
    <h4 [translate]="title"></h4>
    <button
        (click)="close()"
        aria-label="Close"
        class="btn-close close pull-right"
        type="button"
    >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div
        class="content"
        [innerHTML]="message | translate : { date: _date }"
    ></div>
    <div *ngIf="textZone" class="textZone">
        <span>{{ 'inclusion.medicalJustification' | translate }} :</span>
        <textarea #textbox (change)="comment = textbox.value" rows="2">{{
            comment
        }}</textarea>
    </div>
    <div class="d-flex flex-row-reverse align-items-stretch">
        <button
            (click)="confirm()"
            class="btn btn-primary m-2 uppercase"
            translate="inclusion.confirmYes"
        ></button>
        <button
            (click)="close()"
            class="btn btn-default m-2 uppercase"
            translate="inclusion.cancel"
        ></button>
    </div>
</div>
