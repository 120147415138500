import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ImmunosuppressantService {
    constructor(private http: HttpClient) {}

    getImmunosuppressants() {
        return this.http
            .get<any>(`${environment.apiUrl}/treatment`)
            .pipe(map(({ immunosuppressants }) => immunosuppressants));
    }
}
