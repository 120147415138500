import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '../icon';
import { UserNotificationComponent } from './user-notification.component';
import { NotificationLevelStylesDirective } from './directives/notification-level-styles.directive';

@NgModule({
    declarations: [UserNotificationComponent, NotificationLevelStylesDirective],
    imports: [CommonModule, IconModule],
    exports: [UserNotificationComponent],
})
export class UserNotificationModule {}
