import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

import { IconModule } from '@ui/icon';

import { SidebarComponent } from './sidebar.component';
import { HoverDirective } from './directives/hover.directive';

@NgModule({
    declarations: [SidebarComponent, HoverDirective],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        RouterModule,
        IconModule,
        PerfectScrollbarModule,
        TranslateModule,
    ],
    exports: [SidebarComponent],
})
export class SidebarModule {}
