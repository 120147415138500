import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { MatSelectModule } from '@angular/material/select';
import { HeaderComponent } from './header.component';
import { IconModule } from '../icon';

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IconModule,
        TranslateModule.forChild(),
        MatSelectModule,
        FormsModule,
    ],
    exports: [HeaderComponent],
})
export class HeaderModule {}
