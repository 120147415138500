import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class NephroInclusionGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return (
            this.authService.isAdminMedical ||
            this.authService.isNephro ||
            this.authService.isSalesman
        );
    }
}
