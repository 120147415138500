import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';

import { InclusionFastComponent } from '@app/patients/modals/inclusion-fast/inclusion-fast.component';
import { ButtonMobileAction } from '@ui/btn-action-mobile';
import { AuthService } from '@services/security';
import { ModalRightService } from '@services/modal';

import { PatientService } from '@services/patient';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ModalFullService } from '@services/modal/modal-full.service';
import { DocumentService } from '@services/documents';
import { ExportAtComponent } from '@app/dashboard/modals/export-at/export-at.component';
import { RouteDetectorService } from './services/route-detector.service';

@UntilDestroy()
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    isSpecialist = this.authService.isSpecialist;

    private _actions$ = new BehaviorSubject<ButtonMobileAction[]>([]);

    public get actions$(): Observable<ButtonMobileAction[]> {
        return this._actions$.asObservable();
    }

    public get user$(): Observable<any> {
        return this.authService.currentUser;
    }

    constructor(
        private readonly router: Router,
        private readonly routeDetectorService: RouteDetectorService,
        public authService: AuthService,
        private readonly modalRightService: ModalRightService,
        private readonly modalFullService: ModalFullService,
        private readonly patientService: PatientService,
        private readonly documentService: DocumentService,
        private readonly toastr: ToastrService,
        private readonly translate: TranslateService,
    ) {}

    public ngOnInit(): void {
        this._initActions();
        this.routeDetectorService.detectRoute();
    }

    private _initActions(): void {
        const actions = this._getActions();
        this._actions$.next(actions);
    }

    private _getActions(): ButtonMobileAction[] {
        if (this.authService.isAdminMedical || this.authService.isSalesman) {
            return [
                {
                    action: this._openFastInclusion.bind(this),
                    translate: 'patients.addPatientCardio',
                },
                {
                    action: this._openNephroInclusion.bind(this),
                    translate: 'patients.addPatientNephro',
                },
            ];
        }

        if (this.authService.isCardio) {
            return [
                {
                    action: this._openFastInclusion.bind(this),
                    translate: 'patients.add',
                },
            ];
        }

        if (this.authService.isNephro) {
            return [
                {
                    action: this._openNephroInclusion.bind(this),
                    translate: 'patients.add',
                },
            ];
        }

        return [];
    }

    private _openFastInclusion() {
        this.modalFullService.open(InclusionFastComponent);
    }

    private _openNephroInclusion() {
        this.router.navigate(['inclusion-nephro']);
    }

    isCompanyName() {
        return (
            this.authService.isCpa ||
            this.authService.isTransplantCenter ||
            this.authService.isTherapeuticCoach
        );
    }

    exportAT() {
        this.modalRightService.open(ExportAtComponent);
    }

    addPatient() {
        if (this.authService.isNephro) {
            this._openNephroInclusion();
        } else if (this.authService.isCardio) {
            this._openFastInclusion();
        }
    }

    addPatientDemo() {
        this.patientService
            .addPatientDemo()
            .pipe(
                tap(({ patient }) => {
                    this.toastr.success(
                        this.translate.instant('dashboard.success', {
                            patientId: patient._id,
                            patientName: `${patient.lastName} ${patient.firstName}`,
                        }),
                        null,
                        {
                            enableHtml: true,
                            messageClass: 'toastLink',
                            closeButton: true,
                        },
                    );
                }),
                catchError((err) => {
                    this.toastr.error(
                        this.translate.instant('dashboard.error'),
                    );
                    return of(err);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }
}
