<ng-container *ngIf="(state$ | async) !== SidebarState.HIDDEN">
    <div class="header-sidebar" [class]="state$ | async">
        <div
            class="circle"
            [class.circle_closed]="(state$ | async) !== SidebarState.CLOSED"
            (click)="toggle()"
        >
            <app-kebab-menu></app-kebab-menu>
        </div>
        <ng-container *ngIf="(state$ | async) !== SidebarState.CLOSED">
            <app-logo class="logo-newcard"></app-logo>
        </ng-container>
    </div>
    <div [class]="[theme, state$ | async]" appHover class="sidebar">
        <div class="body-sidebar">
            <div class="sidebar-top">
                <div
                    routerLinkActive="active"
                    routerLink="/dashboard/unknown"
                    class="btn-nav d-flex flex-row mt-2"
                    [class.btn-nav_closed]="
                        (state$ | async) === SidebarState.CLOSED
                    "
                >
                    <app-home></app-home>
                    <span
                        class="d-flex align-items-center label-nav"
                        translate="sidebar.dashboard"
                        id="dashboard"
                        [class.label-nav_hidden]="
                            (state$ | async) === SidebarState.CLOSED
                        "
                    ></span>
                </div>
                <ng-container *ngFor="let route of routes">
                    <div class="block-nav">
                        <ng-container *ngIf="user.type === 'admin'">
                            <div
                                [translate]="'sidebar.title.' + route.title"
                                [class.title-nav_closed]="
                                    (state$ | async) === SidebarState.CLOSED
                                "
                                class="d-flex title-nav"
                            ></div>
                        </ng-container>

                        <ng-container *ngFor="let item of route.links">
                            <a
                                [routerLink]="item.link"
                                [id]="item.link"
                                [class.btn-nav_closed]="
                                    (state$ | async) === SidebarState.CLOSED
                                "
                                routerLinkActive="active"
                                class="d-flex flex-row btn-nav"
                            >
                                <app-icon [name]="item.icon"></app-icon>
                                <span
                                    [translate]="'sidebar.label.' + item.label"
                                    [class.title-nav_closed]="
                                        (state$ | async) === SidebarState.CLOSED
                                    "
                                    [class.label-nav_hidden]="
                                        (state$ | async) === SidebarState.CLOSED
                                    "
                                    class="d-flex align-items-center label-nav"
                                ></span>
                            </a>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="sidebar-footer">
                <div class="divider">
                    <div class="d-flex separator"></div>
                </div>
                <div
                    class="btn-nav d-flex flex-row profile"
                    id="profile"
                    [class.btn-nav_closed]="
                        (state$ | async) === SidebarState.CLOSED
                    "
                    routerLinkActive="active"
                    routerLink="/profile/identity"
                >
                    <app-icon name="profile"></app-icon>
                    <div class="d-flex flex-column">
                        <div
                            [class.title-nav_closed]="
                                (state$ | async) === SidebarState.CLOSED
                            "
                            [class.label-nav_hidden]="
                                (state$ | async) === SidebarState.CLOSED
                            "
                            class="d-flex align-items-center label-nav"
                        >
                            <span
                                class="notranslate"
                                *ngIf="!user.sharedProfile"
                                >{{ user.firstName }} {{ user.lastName }}</span
                            >
                            <span
                                *ngIf="user.sharedProfile"
                                class="notranslate sharedProfile"
                                >{{ user.companyName }}</span
                            >
                        </div>
                        <span
                            [translate]="'sidebar.role.' + user.type"
                            [class.title-nav_closed]="
                                (state$ | async) === SidebarState.CLOSED
                            "
                            [class.label-nav_hidden]="
                                (state$ | async) === SidebarState.CLOSED
                            "
                            class="d-flex align-items-center sublabel-nav"
                        ></span>
                    </div>
                </div>
                <div
                    (click)="changeAccount()"
                    id="logout"
                    class="btn-nav d-flex flex-row"
                    *ngIf="user.isPSC"
                >
                    <app-icon-cached></app-icon-cached>
                    <span
                        class="d-flex align-items-center label-nav"
                        translate="sidebar.label.switchAccount"
                        [class.label-nav_hidden]="
                            (state$ | async) === SidebarState.CLOSED
                        "
                    ></span>
                </div>
                <div
                    (click)="logout()"
                    id="logout"
                    class="btn-nav d-flex flex-row"
                >
                    <app-logout></app-logout>
                    <span
                        class="d-flex align-items-center label-nav"
                        translate="sidebar.label.logout"
                        [class.label-nav_hidden]="
                            (state$ | async) === SidebarState.CLOSED
                        "
                    ></span>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="main-content" [class]="state$ | async">
    <ng-content></ng-content>
</div>
