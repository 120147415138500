import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../security';

@Injectable({ providedIn: 'root' })
export class BiologicDataGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.getCurrentUser();
        return (
            this.authService.isAdminMedical ||
            this.authService.isNephro ||
            this.authService.isSalesman ||
            (this.authService.isCardio && currentUser.authorizeBiologicData)
        );
    }
}
