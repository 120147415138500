export interface SidebarRoute {
    title: RouteCategory;
    links: SidebarRouteLink[];
}

export interface SidebarRouteLink {
    link: string;
    icon: string;
    label: string;
}

export enum RouteCategory {
    ALERTS = 'alerts',
    USER = 'user',
    OTHER = 'other',
}
