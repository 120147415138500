import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BtnActionMobileComponent } from './btn-action-mobile.component';

@NgModule({
    declarations: [BtnActionMobileComponent],
    imports: [CommonModule, TranslateModule.forChild()],
    exports: [BtnActionMobileComponent],
})
export class BtnActionMobileModule {}
