import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConversionService {
    convert = {
        creatinine: 8.84,
        proteinuria24h: 0.001,
        proteinuriaSample: 0.001,
        hemoglobine: 10,
        plaquette: 0.001,
        glucose: 5.55,
        cholesterol: 2.59,
        ldlc: 2.59,
        urea: 2.59,
        tag: 1.13,
        bilirubine: 1.17,
        calcium: 0.025,
        phosphore: 0.0323,
        magnesium: 0.0411,
        rac: 8.84,
    };

    defaultUnitIS = {
        'prednisolone-solupred': 'mg/L',
        'ciclosporine-neoral': 'µg/L',
        'ciclosporine-neoral-blood': 'µg/L',
        'tacrolimus-prograf': 'ng/mL',
        'tacrolimus-envarsus': 'ng/mL',
        'tacrolimus-envarsus-lp': 'ng/mL',
        'tacrolimus-advagraf': 'ng/mL',
        'tacrolimus-advgraf-lp': 'ng/mL',
        'tacrolimus-adoport': 'ng/mL',
        'tacrolimus-generique': 'ng/mL',
        'sirolimus-rapamune': 'µg/L',
        'everolimus-certican': 'µg/L',
        'mycophenolate-mofetil-cellcept': 'mg.h/L',
        'mycophenolate-sodique-myfortic': 'mg.h/L',
        'leflunomide-arava': 'mg/L',
        belatacept: 'mg/L',
    };

    defaultUnit = {
        bnp: 'pg/ml',
        ntprobnp: 'pg/nl',
        urea: 'g/L',
        creatinine: 'mg/L',
        ckdEpi: 'ml/min/1.73 m²',
        mdrd: 'ml/min/1.73 m²',
        proteinuria24h: 'mg/24h',
        proteinuriaSample: 'mg/g',
        hemoglobine: 'g/dL',
        plaquette: 'éléments/mm3',
        glucose: 'g/L',
        cholesterol: 'g/L',
        ldlc: 'g/L',
        tag: 'g/L',
        bilirubine: 'mg/L',
        calcium: 'mg/L',
        phosphore: 'mg/L',
        potassium: 'mEq/L',
        sodium: 'mEq/L',
        bicarbonate: 'mEq/L',
        monocyte: 'éléments/mm3',
        lymphocyte: 'éléments/mm3',
        leucocyte: 'éléments/mm3',
        pnn: 'éléments/mm3',
        pneo: 'éléments/mm3',
        pnb: 'éléments/mm3',
        alat: 'Ul/L',
        asat: 'Ul/L',
        ggt: 'Ul/L',
        albuminemie: 'g/L',
        protidemie: 'g/L',
        vitamineD: 'ng/mL',
        pth: 'pg/mL',
        crp: 'mg/L',
        hba1c: '%',
        acidUrique: 'mg/L',
        erythrocyte: 'T/L',
        azathioprine: 'mg/L',
        tsh: 'mg/L',
        chloruremia: 'mg/L',
        magnesium: 'mg/L',
        rac: 'mg/mmol',
        ...this.defaultUnitIS,
    };

    convertValue(name, value, unit) {
        if (this.defaultUnit[name] !== unit && this.convert[name]) {
            return value * this.convert[name];
        }

        return value;
    }

    convertMedicalData(medicalData) {
        medicalData.creatinine = this.convertValue(
            'creatinine',
            medicalData.creatinine,
            medicalData.creatinineUnit,
        );

        medicalData.proteinuria24h = this.convertValue(
            'proteinuria24h',
            medicalData.proteinuria24h,
            medicalData.proteinuria24hUnit,
        );

        medicalData.proteinuriaSample = this.convertValue(
            'proteinuriaSample',
            medicalData.proteinuriaSample,
            medicalData.proteinuriaSampleUnit,
        );

        medicalData.rac = this.convertValue(
            'rac',
            medicalData.rac,
            medicalData.racUnit,
        );
    }

    convertRac(medicalData) {
        if (medicalData.creatinineUnit !== this.defaultUnit.creatinine) {
            medicalData.rac *= this.convert.creatinine;
        }
        if (
            medicalData.proteinuriaSampleUnit !==
            this.defaultUnit.proteinuriaSample
        ) {
            medicalData.rac /= this.convert.proteinuriaSample;
        }
        if (medicalData.racUnit !== this.defaultUnit.rac) {
            medicalData.rac *= this.convert.rac;
        }
    }
}
