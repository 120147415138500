import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScreenService } from '@utils/screen';
import { CookieService } from 'ngx-cookie';
import { environment } from '@environments/environment';
import { SidebarTheme } from '../types/sidebar-theme.enum';
import { SidebarState } from '../types/sidebar-state.enum';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    previousState: SidebarState;
    changePSA = false;
    private _state$ = new BehaviorSubject<SidebarState>(this.getInitialState());

    public get state$(): Observable<SidebarState> {
        return this._state$.asObservable();
    }

    public get state(): SidebarState {
        return this._state$.value;
    }

    public set state(state: SidebarState) {
        this._state$.next(state);
        this.saveState(state);
    }

    constructor(
        private screenService: ScreenService,
        private cookieService: CookieService,
    ) {}

    toggle(): void {
        const isClosed = this.state === SidebarState.CLOSED;
        this.state = SidebarState[isClosed ? 'OPEN' : 'CLOSED'];
    }

    hide(): void {
        if (this.state !== SidebarState.HIDDEN) {
            this.previousState = this.state;
            this.state = SidebarState.HIDDEN;
        }
    }

    show(): void {
        if (this.state === SidebarState.HIDDEN) {
            this.state = this.previousState;
        }
    }

    saveState(state: SidebarState) {
        if (!state) {
            return;
        }

        const expires = new Date();
        expires.setTime(expires.getTime() + 8 * 60 * 60 * 1000);
        this.cookieService.put('sidebarState', state, {
            domain: environment.cookieDomain,
            sameSite: 'lax',
            expires,
        });
    }

    clearState(): void {
        this.cookieService.remove('sidebarState');
    }

    getInitialState(): SidebarState {
        const savedState = this.cookieService.get(
            'sidebarState',
        ) as SidebarState;

        if (savedState && savedState !== SidebarState.HIDDEN) {
            return savedState;
        }

        if (this.screenService.desktop) {
            return SidebarState.OPEN;
        }

        return SidebarState.CLOSED;
    }

    getUserTheme(userType: string, prefix = 'sidebar'): string {
        if (
            ['cardiologist', 'doctor', 'ide', 'therapeutic_coach'].includes(
                userType,
            )
        ) {
            return `${prefix}-${SidebarTheme.CARDIO}`;
        }

        if (['nephrologist', 'transplant_center'].includes(userType)) {
            return `${prefix}-${SidebarTheme.NEPHRO}`;
        }

        return `${prefix}-${SidebarTheme.ADMIN}`;
    }
}
