<div class="d-flex flex-column p-2">
    <div class="modal-title p-2" translate="patients.exportModal.titleAT"></div>
    <div class="p-2">
        <label
            class="form-label"
            for="date"
            translate="patients.exportModal.date"
        ></label>
        <input
            (bsValueChange)="onDateChange($event)"
            [bsConfig]="{
                isAnimated: true,
                dateInputFormat: 'DD/MM/YYYY',
                showWeekNumbers: false
            }"
            [bsValue]="date"
            bsDatepicker
            class="form-control"
            id="date"
            type="text"
            autocomplete="off"
        />
    </div>

    <div class="d-md-flex d-none flex-row-reverse">
        <button
            class="btn btn-default"
            translate="patient.prescription.modal.generate"
            (click)="exportAt()"
        ></button>
    </div>
    <div class="d-flex d-md-none justify-content-center flex-row">
        <button
            class="btn btn-default"
            translate="patient.prescription.modal.generate"
            (click)="exportAt()"
        ></button>
    </div>
</div>
