import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { IconDirective } from './directives/icon-host.directive';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
    @ViewChild(IconDirective, { read: ViewContainerRef })
    private _viewContainer: ViewContainerRef;

    @Input()
    public name = '';

    public ngOnInit(): void {
        const componentName = this._transformIconName();
        this._createIconDynamically(componentName);
    }

    private _transformIconName(): string {
        const name = this.name
            .split('-')
            .map((part) => {
                const transformedPart = `${
                    part.charAt(0).toUpperCase() + part.slice(1)
                }`;
                return transformedPart;
            })
            .join('');

        return `${name}Component`;
    }

    private _createIconDynamically(componentName: string): void {
        const checkForCamelCase = this.name.match(/[A-Z]/g);

        // If icon name has value "iconName" - need to transform to "icon-name".
        if (checkForCamelCase?.length > 0) {
            this.name = checkForCamelCase.reduce((acc, whatReplace) => {
                this.name = this.name.replace(
                    whatReplace,
                    `-${whatReplace.toLowerCase()}`,
                );
                return this.name;
            }, '');
        }

        import(`./components/${this.name}/${this.name}.component.ts`)
            .then((module) => {
                const component = module[componentName];
                this._viewContainer.createComponent(component);
            })
            .catch((ex) => {
                // eslint-disable-next-line no-console
                console.error('Could not find icon: ', ex);
            });
    }
}
