import { Injectable } from '@angular/core';
import { isArray } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ErrorFormService {
    errors: Observable<any>;
    errorsSubject: BehaviorSubject<any>;

    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {
        this.errorsSubject = new BehaviorSubject<any>([]);
        this.errors = this.errorsSubject.asObservable();
    }

    initErrors(error) {
        let message = '';

        if (isArray(error)) {
            message = error
                .filter((e) => e.error)
                .reduce(
                    (acc, cur) =>
                        `${acc} - ${this.translate.instant(cur.error)}</br>`,
                    '',
                );
        }

        this.toastr.error(
            message,
            this.translate.instant('errors.patient.title'),
            {
                closeButton: true,
                disableTimeOut: true,
                enableHtml: true,
            },
        );

        this.errorsSubject.next(isArray(error) ? error : []);
    }

    removeError(error) {
        this.errorsSubject.next(
            this.errorsSubject
                .getValue()
                .filter(({ field }) => field !== error),
        );
    }

    resetErrors() {
        this.errorsSubject.next([]);
    }

    removeTreatment(index) {
        const errors = this.errorsSubject
            .getValue()
            .filter(
                ({ field }) =>
                    !field?.startsWith(`treatmentImmunosuppressant.${index}`),
            );

        errors
            .filter((error) =>
                error.field?.startsWith('treatmentImmunosuppressant'),
            )
            .forEach((error) => {
                const i = error.field['treatmentImmunosuppressant.'.length];

                if (i > index) {
                    error.field = error.field.replace(i, +i - 1);
                }
            });

        this.errorsSubject.next(errors);
    }
}
