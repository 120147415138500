import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: 'specialist',
                loadChildren: () =>
                    import('./pages/specialist').then(
                        ({ SpecialistModule }) => SpecialistModule,
                    ),
            },
            {
                path: 'salesman',
                loadChildren: () =>
                    import('./pages/salesman').then(
                        ({ SalesmanModule }) => SalesmanModule,
                    ),
            },
            {
                path: 'doctor',
                loadChildren: () =>
                    import('./pages/doctor').then(
                        ({ DoctorModule }) => DoctorModule,
                    ),
            },
            {
                path: 'observer',
                loadChildren: () =>
                    import('./pages/doctor').then(
                        ({ DoctorModule }) => DoctorModule,
                    ),
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('./pages/admin').then(
                        ({ AdminModule }) => AdminModule,
                    ),
            },
            {
                path: 'accounting',
                loadChildren: () =>
                    import('./pages/accounting').then(
                        ({ AccountingModule }) => AccountingModule,
                    ),
            },
            {
                path: 'installer',
                loadChildren: () =>
                    import('./pages/installer').then(
                        ({ InstallerModule }) => InstallerModule,
                    ),
            },
            {
                path: 'therapeutic-coach',
                loadChildren: () =>
                    import('./pages/therapeutic-coach').then(
                        ({ TherapeuticCoachModule }) => TherapeuticCoachModule,
                    ),
            },
            {
                path: 'transplant-center',
                loadChildren: () =>
                    import('./pages/transplant-center').then(
                        ({ TransplantCenterModule }) => TransplantCenterModule,
                    ),
            },
        ],
    },
    {
        path: 'unknown',
        loadChildren: () =>
            import('./pages/unknown-role').then(
                ({ UnknownRoleModule }) => UnknownRoleModule,
            ),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
