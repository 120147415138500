import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-warning',
    templateUrl: './warning.component.svg',
    styleUrls: ['./warning.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningComponent {
    @Input()
    public type = 'default' as 'default' | 'danger';
}
