import { Injectable } from '@angular/core';
import { differenceInYears } from 'date-fns';
import { uniq } from 'lodash';

// TODO: Replace "Magic Numbers"

@Injectable({ providedIn: 'root' })
export class CalculateService {
    calculateBiologicData(patient) {
        const checkMedicalData =
            patient.birthDate &&
            patient.medicalData.creatinine &&
            patient.medicalData.proteinuria24hUnit &&
            ['female', 'male'].includes(patient.sex);

        if (!checkMedicalData) {
            return;
        }

        let creatinine = patient.medicalData.creatinine;

        if (patient.medicalData.creatinineUnit !== 'mg/L') {
            creatinine /= 8.84;
        }

        const ckdEpi = this.getckdEpi(patient, creatinine);
        const mdrd = this.getMdrd(patient, creatinine);

        patient.medicalData.ckdEpi =
            ckdEpi > 10 ? Math.round(ckdEpi) : Math.round(ckdEpi * 10) / 10;
        patient.medicalData.mdrd =
            mdrd > 10 ? Math.round(mdrd) : Math.round(mdrd * 10) / 10;
    }

    getckdEpi(patient, creatinine) {
        const age = differenceInYears(new Date(), patient.birthDate) || 1;
        const creat = creatinine / 10;

        if (patient.sex === 'female') {
            if (creat > 0.7) {
                return 144 * (creat / 0.7) ** -1.209 * 0.993 ** age;
            }

            return 144 * (creat / 0.7) ** -0.329 * 0.993 ** age;
        }

        if (patient.sex === 'male') {
            if (creat > 0.9) {
                return 141 * (creat / 0.9) ** -1.209 * 0.993 ** age;
            }

            return 141 * (creat / 0.9) ** -0.411 * 0.993 ** age;
        }
    }

    getMdrd(patient, creatinine) {
        const age = differenceInYears(new Date(), patient.birthDate) || 1;
        const creat = creatinine * 8.84;

        if (patient.sex === 'female') {
            return 186 * (creat * 0.0113) ** -1.154 * age ** -0.203 * 0.742;
        }

        if (patient.sex === 'male') {
            return 186 * (creat * 0.0113) ** -1.154 * age ** -0.203;
        }
    }

    getTreatmentIs(patient, history?) {
        const immunosuppressantsHistory =
            patient.treatmentImmunosuppressantHistory
                .sort((t1, t2) => t2.date - t1.date)
                .map((treatment) => treatment?.treatmentImmunosuppressant);
        const immunosuppressants = [
            ...patient.treatmentImmunosuppressant,
            ...immunosuppressantsHistory.flat(),
        ];
        const mappedImmunosuppressants = uniq(
            immunosuppressants.map((item) => item.name),
        );

        if (history) {
            return mappedImmunosuppressants.filter(
                (item) => history[item] && history[item] !== '-',
            );
        }
        return mappedImmunosuppressants;
    }
}
