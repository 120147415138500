import { Directive, HostListener } from '@angular/core';

import { SidebarService } from '../services/sidebar.service';
import { SidebarState } from '../types/sidebar-state.enum';

@Directive({
    selector: '[appHover]',
})
export class HoverDirective {
    previousState: SidebarState;

    @HostListener('mouseenter')
    public onMouseEnter(): void {
        if (this.sidebarService.state !== SidebarState.OPEN) {
            this.sidebarService.state = SidebarState.HOVER;
        }
    }

    @HostListener('mouseleave')
    public onMouseLeave(): void {
        if (
            !this.sidebarService.changePSA &&
            this.sidebarService.state === SidebarState.HOVER
        ) {
            this.sidebarService.state = SidebarState.CLOSED;
        }
    }

    constructor(private readonly sidebarService: SidebarService) {}
}
