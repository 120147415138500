import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { NotificationLevel } from './types/notification-level.type';

@Component({
    selector: 'app-user-notification',
    templateUrl: './user-notification.component.html',
    styleUrls: ['./user-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class UserNotificationComponent {
    @Input()
    public text = '';

    @Input()
    public level: NotificationLevel = 'blue';

    @Input()
    public close = false;
}
