import { Directive, HostBinding, Input } from '@angular/core';
import { NotificationLevel } from '../types/notification-level.type';

@Directive({
    selector: '[appNotificationLevelStyles]',
})
export class NotificationLevelStylesDirective {
    @Input()
    public appNotificationLevelStyles: NotificationLevel;

    @HostBinding('class.user-notification_white')
    public get white(): boolean {
        return this.appNotificationLevelStyles === 'white';
    }

    @HostBinding('class.user-notification_blue')
    public get blue(): boolean {
        return this.appNotificationLevelStyles === 'blue';
    }

    @HostBinding('class.user-notification_red')
    public get red(): boolean {
        return this.appNotificationLevelStyles === 'red';
    }
}
