import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { forkJoin, throwError } from 'rxjs';
import { FormPatientNephroService } from '@services/form/form-patient-nephro.service';
import { FormatFormService } from './format-form.service';
import { ErrorFormService } from './error-form.service';
import { PatientService } from '../patient';

@Injectable({ providedIn: 'root' })
export class EditionNephroService {
    patient;
    cancelForm;

    constructor(
        private http: HttpClient,
        private formatService: FormatFormService,
        private errorService: ErrorFormService,
        private patientService: PatientService,
        private router: Router,
        private formPatientNephroService: FormPatientNephroService,
    ) {}

    initEdition(id) {
        this.errorService.resetErrors();

        const promises = [
            this.initPatient(id),
            this.formPatientNephroService.loadCarestaff(),
            this.formPatientNephroService.loadImmunosuppressants(),
        ];

        return forkJoin(promises);
    }

    initPatient(id) {
        return this.patientService.getPatient(id, ['logs'], 'nephro').pipe(
            tap((patient) => {
                this.patient = this.formatService.initPatient(
                    patient,
                    'nephro',
                );
            }),
        );
    }

    editPatient() {
        if (this.patient.demo) {
            this.router.navigate(['patient', this.patient._id]);

            return;
        }

        if (!this.patient.treatment?.antidiabetes) {
            this.patient.treatment.metformine = false;
            this.patient.treatment.insuline = false;
            this.patient.treatment.other = false;
            this.patient.treatment.otherDetail = '';
        }

        const formData = this.formatService.formatPatientNephro(this.patient);

        return this.http
            .put<any>(
                `${environment.apiUrl}/patient/${this.patient._id}`,
                formData,
                {
                    params: { disease: 'nephro' },
                },
            )
            .pipe(
                tap(() => {
                    this.router.navigate(['patient', this.patient._id]);
                }),
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(() => err);
                }),
            );
    }
}
