import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MOBILE_MAX_WIDTH } from '../constants/mobile-max-width.constant';
import { TABLET_MAX_WIDTH } from '../constants/tablet-max-width.constant';

@Injectable({
    providedIn: 'root',
})
export class ScreenService {
    private _width$ = new BehaviorSubject<number>(0);
    private _mobile$ = new BehaviorSubject<boolean>(false);
    private _tablet$ = new BehaviorSubject<boolean>(false);
    private _desktop$ = new BehaviorSubject<boolean>(false);
    private _resize$ = new BehaviorSubject<boolean>(false);

    public get width$(): Observable<number> {
        return this._width$.asObservable();
    }

    public get mobile$(): Observable<boolean> {
        return this._mobile$.asObservable();
    }

    public get tablet$(): Observable<boolean> {
        return this._tablet$.asObservable();
    }

    public get desktop$(): Observable<boolean> {
        return this._desktop$.asObservable();
    }

    public get resize$(): Observable<boolean> {
        return this._resize$.asObservable();
    }

    public set width(width: number) {
        if (this._width$.value === width) {
            return;
        }

        this._width$.next(width);
        this._mobile$.next(width < MOBILE_MAX_WIDTH);
        this._tablet$.next(
            width > MOBILE_MAX_WIDTH && width <= TABLET_MAX_WIDTH,
        );
        this._desktop$.next(width > TABLET_MAX_WIDTH);
        this._resize$.next(true);
    }

    public get width(): number {
        return this._width$.value;
    }

    public get mobile(): boolean {
        return this._mobile$.value;
    }

    public get tablet(): boolean {
        return this._tablet$.value;
    }

    public get desktop(): boolean {
        return this._desktop$.value;
    }
}
