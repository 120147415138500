import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonMobileAction } from './interfaces/action.interface';

@Component({
    selector: 'btn-action-mobile',
    templateUrl: './btn-action-mobile.component.html',
    styleUrls: ['./btn-action-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnActionMobileComponent {
    @Input()
    actions: ButtonMobileAction[];

    @Input()
    displayBtn = 'd-md-none';
}
