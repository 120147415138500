import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@services/security';

import { AdminRole } from './types/admin-role.type';
import { UserRole } from './types/user-role.type';

@Directive({
    selector: '[appCanAccess]',
})
export class CanAccessDirective {
    @Input()
    public set appCanAccess(roles: (AdminRole | UserRole)[]) {
        const user = this.authService.getCurrentUser();
        const userRoles = [user?.type, user?.adminType];

        const checkRoles = roles.some((role) =>
            userRoles.some((userRole) => userRole === role),
        );

        if (checkRoles) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly templateRef: TemplateRef<any>,
        private authService: AuthService,
    ) {}
}
