import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-package-info',
    templateUrl: './package-info.component.html',
    styleUrls: ['./package-info.component.scss'],
})
export class PackageInfoComponent implements OnInit {
    modalRef?: BsModalRef;

    constructor(private modalService: BsModalService) {}

    openModal(template: TemplateRef<void>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
    }

    ngOnInit(): void {}
}
